import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'
import Messaging from '../messaging.js';
import common from '../common';

import EonnButton from '../components/button';
import EonnField from '../components/field';
import EonnTitle from '../components/title';
import { mstyles } from '../constants/MainStyles';

const styles = StyleSheet.create({
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: '#ccc',
  },
  divider: {
    height: 1,
    width: '80%',
    backgroundColor: '#ccc',
    marginVertical: 20,
  },
  receiptRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    width: '80%',
  },
  receiptLabel: {
    width: '50%',
    fontSize: 20,
    lineHeight: 22,
    textAlign: 'right',
    marginLeft: 10,
    marginRight: 5,
  },
  receiptValue: {
    width: '50%',
    fontSize: 20,
    lineHeight: 22,
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 10,
  },
  tipRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  tipBox: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginHorizontal: 5,
    minWidth: 75,
    height: 65,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedTipBox: {
    borderColor: '#d22f25',
    backgroundColor: '#d22f25',
  },
  tipPercentage: {
    fontSize: 14,
    lineHeight: 16,
    paddingTop: 2,
  },
  tipAmount: {
    fontSize: 16,
    lineHeight: 22,
    paddingTop: 4,
  },
  customTipField: {
    marginHorizontal: 0,
    paddingVertical: 0,
    borderWidth: 0,
    paddingHorizontal: 7,
    paddingBottom: 1,
    fontSize: 16,
    lineHeight: 22,
    width: 95,
    borderRadius: 5,
    backgroundColor: '#fff',
  },
  customTipLabel: {
    fontSize: 14,
    lineHeight: 22,
    marginBottom: 0,
    paddingHorizontal: 5,
  },
  tipShadow: {
    width: "80%",
    height: 3,
    maxWidth: '90%',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }
});

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      err: "",
      selectedTipPercentage: null,
      isCustomTipSelected: false,
      customTipAmount: 0,
      tipPercentages: [10, 15, 20],
      total: Number(global.consult.price || 0) + Number(global.consult.t_tip || 0),
    };
    console.log("total", this.state.total);
    console.log("total.tofixed", this.state.total.toFixed(2));
  }

  onTipPercentagePress = (percentage) => {
    const tipAmount = (global.consult.price || 0) * (percentage / 100);
    Object.assign(global.consult, { t_tip: tipAmount });
    this.setState({
      selectedTipPercentage: percentage,
      isCustomTipSelected: false,
      total: Number(global.consult.price || 0) + Number(global.consult.t_tip || 0),
    });
  };

  renderTipPercentageBox = (percentage) => {
    const isSelected = this.state.selectedTipPercentage === percentage;
    const tipAmount = ((global.consult.price || 0) * (percentage / 100)).toFixed(2);
    return (
      <View style={{alignItems:"center"}} key={percentage}>
        <TouchableOpacity
          activeOpacity={1}
          key={percentage}
          style={[
            styles.tipBox,
            isSelected && styles.selectedTipBox,
          ]}
          onPress={() => this.onTipPercentagePress(percentage)}
        >
          <Text style={[
            mstyles.mfont,
            styles.tipPercentage,
            isSelected && { color: '#fff' },
          ]}>{percentage}%</Text>
          <Text style={[
            mstyles.mfont,
            styles.tipAmount,
            isSelected && { color: '#fff' },
          ]}>${tipAmount}</Text>
        </TouchableOpacity>
        <View style={[
          styles.tipShadow,
          { backgroundColor: isSelected ? '#e2e0e0' : '#fff' }
        ]}></View>
      </View>
    );
  };

  renderCustomTipField = () => {
    const isSelected = this.state.isCustomTipSelected;
    return (
      <View style={{alignItems:"center"}}>
        <TouchableOpacity
          activeOpacity={1}
          style={[
            styles.tipBox,
            { paddingHorizontal: 0, paddingVertical: 0 },
            isSelected && styles.selectedTipBox,
          ]}
          onPress={() => {
            if (!isSelected) {
              Object.assign(global.consult, { t_tip: this.state.customTipAmount });
            }
            this.setState({
              isCustomTipSelected: true,
              selectedTipPercentage: null,
              total: Number(global.consult.price || 0) + Number(global.consult.t_tip || 0),
            });
          }}
        >
          <EonnField
            labelStyle={[
              styles.customTipLabel,
              isSelected && { color: '#fff' },
            ]}
            style={[
              styles.customTipField
            ]}
            onChange={(value) => {
              Object.assign(global.consult, { tip: parseFloat(value) });
              this.setState({
                selectedTipPercentage: null,
                isCustomTipSelected: true,
                customTipAmount: parseFloat(value),
                total: Number(global.consult.price || 0) + Number(global.consult.t_tip || 0),
              });
            }}
            value={global.consult.t_tip?.toFixed(2) || ""}
          >
            Custom Tip
          </EonnField>
        </TouchableOpacity>
          <View style={[
            styles.tipShadow,
            { backgroundColor: isSelected ? '#e2e0e0' : '#fff' }
          ]}></View>
      </View>
    );
  };
  
  render() {


    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Final Receipt</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }

        <Image source={{uri: global.consult.t_profilePicture}} style={styles.profileImage} />
        <View style={styles.divider} />
        <View style={styles.receiptRow}>
          <Text style={[mstyles.text, styles.receiptLabel]}>Virtual Consultation:</Text>
          <Text style={[mstyles.text, styles.receiptValue]}>${global.consult.price || "?"}.00</Text>
        </View>

        <Text style={[mstyles.text, { marginTop: 20, fontSize:22 }]}>Add a tip:</Text>
        <View style={styles.tipRow}>
          {this.state.tipPercentages.map((percentage) => this.renderTipPercentageBox(percentage))}
          {this.renderCustomTipField()}          
        </View>
        <View style={styles.divider} />
        <View style={styles.receiptRow}>
        <Text style={[mstyles.text, styles.receiptLabel, { fontSize:22 }, ]}>Total:</Text>
          <Text style={[mstyles.text, styles.receiptValue, { fontSize:22 }, ]}>${this.state.total.toFixed(2)}</Text>
        </View>

        <EonnButton
          navigationProps={this.props.navigation}
          onPress={() => {
            common.handlePayment(Number(global.consult.t_tip || 0))
            .then(() => {
              return Messaging.sendEmail(global.consult.t_email, "Payment sent from: "+global.user.name,
              "You've received a new payment in the amount of $"+(this.state.total.toFixed(2))+
              " from "+global.user.name+" for the virtual consultation on "+new Date().toLocaleString()+".",
              `
              <img src="https://consult.autoteletech.com/assets/logoHeader.jpg">
              <h1>You've received a new payment!</h1>
              <p>From: ${global.user.name}</p>
              <p>Amount: $${this.state.total.toFixed(2)}</p>
              <p>Date: ${new Date().toLocaleString()}</p>
              <p>Issue: ${global.consult.issue}</p>
              <p>Vehicle: ${global.consult.c_vehicle}</p>
              <br>
              <p>Thank you for using Auto TeleTech!</p>
              `);
            })
            .then((response) => {
              console.log("response: ", response);
              global.consult.status = 'completed';
              return common.storeUserData()
            })
            .then(() => FirebaseConsult.updateConsultInfo() ) 
            .then(() => {
              common.resetConsult();
              this.props.navigation.navigate(global.auth.currentUser ? 'C_Reports' : 'C_SignUp');
            })
            .catch((error) => {
              console.log("error: ", error);
              this.setState({ err: error.message });
            });
          }}
        >
          Checkout
        </EonnButton>

      </ScrollView>
    );
  }
}