import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnIconCheckboxes from '../components/icon_checkboxes';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
      selected: [],
    };
    console.log(global.user);
    
  }

  render() {
    const brands = [
      { icon: require('../assets/images/experience/acura.png'), icon2: require('../assets/images/experience/acura2.png'), title: 'Acura' },
      { icon: require('../assets/images/experience/alfa_romeo.png'), icon2: require('../assets/images/experience/alfa_romeo2.png'), title: 'Alfa Romeo' },
      { icon: require('../assets/images/experience/audi.png'), icon2: require('../assets/images/experience/audi2.png'), title: 'Audi' },
      { icon: require('../assets/images/experience/bentley.png'), icon2: require('../assets/images/experience/bentley2.png'), title: 'Bentley' },
      { icon: require('../assets/images/experience/bmw.png'), icon2: require('../assets/images/experience/bmw2.png'), title: 'BMW' },
      { icon: require('../assets/images/experience/bugatti.png'), icon2: require('../assets/images/experience/bugatti2.png'), title: 'Bugatti' },
      { icon: require('../assets/images/experience/buick.png'), icon2: require('../assets/images/experience/buick2.png'), title: 'Buick' },
      { icon: require('../assets/images/experience/cadillac.png'), icon2: require('../assets/images/experience/cadillac2.png'), title: 'Cadillac' },
      { icon: require('../assets/images/experience/chevrolet.png'), icon2: require('../assets/images/experience/chevrolet2.png'), title: 'Chevrolet' },
      { icon: require('../assets/images/experience/chrysler.png'), icon2: require('../assets/images/experience/chrysler2.png'), title: 'Chrysler' },
      { icon: require('../assets/images/experience/dodge.png'), icon2: require('../assets/images/experience/dodge2.png'), title: 'Dodge' },
      { icon: require('../assets/images/experience/ferrari.png'), icon2: require('../assets/images/experience/ferrari2.png'), title: 'Ferrari' },
      { icon: require('../assets/images/experience/fiat.png'), icon2: require('../assets/images/experience/fiat2.png'), title: 'Fiat' },
      { icon: require('../assets/images/experience/ford.png'), icon2: require('../assets/images/experience/ford2.png'), title: 'Ford' },
      { icon: require('../assets/images/experience/genesis.png'), icon2: require('../assets/images/experience/genesis2.png'), title: 'Genesis' },
      { icon: require('../assets/images/experience/gmc.png'), icon2: require('../assets/images/experience/gmc2.png'), title: 'GMC' },
      { icon: require('../assets/images/experience/honda.png'), icon2: require('../assets/images/experience/honda2.png'), title: 'Honda' },
      { icon: require('../assets/images/experience/hyundai.png'), icon2: require('../assets/images/experience/hyundai2.png'), title: 'Hyundai' },
      { icon: require('../assets/images/experience/infiniti.png'), icon2: require('../assets/images/experience/infiniti2.png'), title: 'Infiniti' },
      { icon: require('../assets/images/experience/jaguar.png'), icon2: require('../assets/images/experience/jaguar2.png'), title: 'Jaguar' },
      { icon: require('../assets/images/experience/jeep.png'), icon2: require('../assets/images/experience/jeep2.png'), title: 'Jeep' },
      { icon: require('../assets/images/experience/kia.png'), icon2: require('../assets/images/experience/kia2.png'), title: 'Kia' },
      { icon: require('../assets/images/experience/lamborghini.png'), icon2: require('../assets/images/experience/lamborghini2.png'), title: 'Lamborghini' },
      { icon: require('../assets/images/experience/land_rover.png'), icon2: require('../assets/images/experience/land_rover2.png'), title: 'Land Rover' },
      { icon: require('../assets/images/experience/lexus.png'), icon2: require('../assets/images/experience/lexus2.png'), title: 'Lexus' },
      { icon: require('../assets/images/experience/lincoln.png'), icon2: require('../assets/images/experience/lincoln2.png'), title: 'Lincoln' },
      { icon: require('../assets/images/experience/lotus.png'), icon2: require('../assets/images/experience/lotus2.png'), title: 'Lotus' },
      { icon: require('../assets/images/experience/maserati.png'), icon2: require('../assets/images/experience/maserati2.png'), title: 'Maserati' },
      { icon: require('../assets/images/experience/mazda.png'), icon2: require('../assets/images/experience/mazda2.png'), title: 'Mazda' },
      { icon: require('../assets/images/experience/mercedes_benz.png'), icon2: require('../assets/images/experience/mercedes_benz2.png'), title: 'Mercedes-Benz' },
      { icon: require('../assets/images/experience/mini.png'), icon2: require('../assets/images/experience/mini2.png'), title: 'Mini' },
      { icon: require('../assets/images/experience/mitsubishi.png'), icon2: require('../assets/images/experience/mitsubishi2.png'), title: 'Mitsubishi' },
      { icon: require('../assets/images/experience/nissan.png'), icon2: require('../assets/images/experience/nissan2.png'), title: 'Nissan' },
      { icon: require('../assets/images/experience/porsche.png'), icon2: require('../assets/images/experience/porsche2.png'), title: 'Porsche' },
      { icon: require('../assets/images/experience/ram.png'), icon2: require('../assets/images/experience/ram2.png'), title: 'Ram' },
      { icon: require('../assets/images/experience/rolls_royce.png'), icon2: require('../assets/images/experience/rolls_royce2.png'), title: 'Rolls-Royce' },
      { icon: require('../assets/images/experience/subaru.png'), icon2: require('../assets/images/experience/subaru2.png'), title: 'Subaru' },
      { icon: require('../assets/images/experience/tesla.png'), icon2: require('../assets/images/experience/tesla2.png'), title: 'Tesla' },
      { icon: require('../assets/images/experience/toyota.png'), icon2: require('../assets/images/experience/toyota2.png'), title: 'Toyota' },
      { icon: require('../assets/images/experience/volkswagen.png'), icon2: require('../assets/images/experience/volkswagen2.png'), title: 'Volkswagen' },
      { icon: require('../assets/images/experience/volvo.png'), icon2: require('../assets/images/experience/volvo2.png'), title: 'Volvo' }
    ];


    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Your vehicle expertise</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <Text style={[mstyles.text,{maxWidth:500}]}>Select the vehicle makes you're most experienced with</Text>
        <EonnIconCheckboxes
          options={brands}
          selected={this.state.selected}
          setSelected={selected => {
            global.user.expertise = selected;
            this.setState({ selected: selected });
          }}
        />
        <EonnButton
          navigationProps={this.props.navigation}
          onPress={() => {
            console.log(global.user);
            this.props.navigation.navigate("T_Profile5");
          }}
          >
          Next</EonnButton>


      </ScrollView>
    );
  }
}