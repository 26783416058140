import React, { Component } from 'react';
import { View, Text, Animated, StyleSheet } from 'react-native';

export default class EonnEllipsis extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dot1Opacity: new Animated.Value(0),
      dot2Opacity: new Animated.Value(0),
      dot3Opacity: new Animated.Value(0),
    };

  }

  componentDidMount() {
    this.animateDots();
  }

  animateDots() {
    const { dot1Opacity, dot2Opacity, dot3Opacity } = this.state;
    Animated.loop(
      Animated.sequence([
        Animated.timing(dot1Opacity, { toValue: 1, duration: 500, useNativeDriver: true }),
        Animated.timing(dot2Opacity, { toValue: 1, duration: 500, useNativeDriver: true }),
        Animated.timing(dot3Opacity, { toValue: 1, duration: 500, useNativeDriver: true }),
        Animated.timing(dot1Opacity, { toValue: 0, duration: 1, useNativeDriver: true }),
        Animated.timing(dot2Opacity, { toValue: 0, duration: 1, useNativeDriver: true }),
        Animated.timing(dot3Opacity, { toValue: 0, duration: 1, useNativeDriver: true }),
      ]
    )).start();
  }

  render() {
    const { dot1Opacity, dot2Opacity, dot3Opacity } = this.state;
    return (
      <View style={{flexDirection:'row', justifyContent:'flex-start', height:StyleSheet.flatten(this.props.style).fontSize+2}}>
        <Animated.Text style={[this.props.style, { opacity: dot1Opacity }]}>.</Animated.Text>
        <Animated.Text style={[this.props.style, { opacity: dot2Opacity }]}>.</Animated.Text>
        <Animated.Text style={[this.props.style, { opacity: dot3Opacity }]}>.</Animated.Text>
      </View>
    );
  }
}