import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'
import Messaging from '../messaging.js';

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
      emailError: false,
      pwError: false,
      password1: "",
      password2: ""
    };
    console.log(global.user);
    
  }


  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>My Account</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <EonnField
          onChange={value => this.setState({ password1: value, pwError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.pwError}
          value={this.state.password1}
          textContentType={"password"}
          secureTextEntry={true}
          >Current Password</EonnField>
        <EonnField
          onChange={value => this.setState({ password2: value, pwError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.pwError}
          value={this.state.password2}
          textContentType={"password"}
          secureTextEntry={true}
          >New Password</EonnField>
        <EonnField
          onChange={value => this.setState({ password3: value, pwError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.pwError}
          value={this.state.password3}
          textContentType={"password"}
          secureTextEntry={true}
          >Confirm New Password</EonnField>
        <EonnButton
          loading={this.state.loading}
          navigationProps={this.props.navigation}
          showRequired={() => this.setState({ showRequired: true })}
          required={() => [
            global.user.email,
            this.state.password1,
            this.state.password2,
            this.state.password3
          ]}
          onPress={() => {
            this.setState({ loading: true });
            if (this.state.password2 === this.state.password3) {
              FirebaseUsers.changePassword(this.state.password1, this.state.password2)
                .then(() => {
                  // Password changed successfully
                  console.log("Password changed successfully");
                  this.setState({ loading: false });
                })
                .catch((error) => {
                  // Handle error changing password
                  let errorText = "";
                  switch (error.code) {
                    case 'auth/wrong-password':
                      errorText = "Incorrect current password";
                      break;
                    case 'auth/weak-password':
                      errorText = "New password is too weak";
                      break;
                    default:
                      errorText = "Error changing password";
                  }
                  this.setState({
                    err: errorText,
                    pwError: true,
                    emailError: false,
                    loading: false,
                  });
                  console.log(error);
                });
            } else {
              this.setState({
                err: "New passwords don't match",
                pwError: true,
                emailError: false,
                loading: false,
              });
            }
          }}
          >
          Change Password</EonnButton>
          
      </ScrollView>
    );
  }
}