import React, { Component } from 'react';
import {
  Alert,
  ActivityIndicator,
  ScrollView,
  View,
  Text,
} from 'react-native';

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import { mstyles } from '../constants/MainStyles';
import common from '../common';
import FirebaseUsers from '../firebase/firebase_users.js'

export default class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: "",
      accountLink: null,
    };

    //global.user.email = "albius0@yahoo.com";

    this.createStripeAccount(global.user.email)
    .then(() => this.createAccountLink())
    .catch((error) => {
      console.error('Error creating Stripe account:', error);
      this.setState({
        loading: false,
        error: 'Failed to create Stripe account',
      });
    });

  }

  async createStripeAccount(email) {
    try {
      console.log("creating account with email:",email);
      const response = await fetch('https://us-central1-eonn-teletech.cloudfunctions.net/createStripeAccount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const { stripeAccountId } = await response.json();
      global.user.stripeId = stripeAccountId;
      console.log("stripe account created:", stripeAccountId);

      let downloadURL = await FirebaseUsers.uploadProfilePicture(global.user.profilePicture)
      console.log('Image uploaded successfully:', downloadURL);
      global.user.profilePicture = downloadURL;

      await FirebaseUsers.updateUserInfo();

      return true;
      
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      this.setState({
        loading: false,
        error: 'Failed to create Stripe account',
      });
      throw error;
    }
  }

  async createAccountLink() {
    try {
      console.log("creating account link:");
      const response = await fetch('https://us-central1-eonn-teletech.cloudfunctions.net/createAccountLink', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accountId: global.user.stripeId,
          successUrl: window.location.href.split('?')[0] + '?side=tech',
          failureUrl: 'https://eonn-teletech.web.app/StripeFailure.htm',
        }),
      });

      if (response.status !== 200) {
        // Handle the error case
        console.error('Error creating Stripe account link: Server returned status', response.status);
        this.setState({
          loading: false,
          error: 'Failed to create Stripe account link',
        });
      } else {
        const responseText = await response.text();
        console.log('Raw response text:', responseText);
        const { url } = JSON.parse(responseText);

        common.storeUserData()
        .then(() => {
          window.location.href = url;
        });
      }
      

    } catch (error) {
      console.error('Error creating Stripe account link:', error);
      this.setState({
        loading: false,
        error: 'Failed to create Stripe account link',
      });
    }
  }

  render() {
    const { loading, accountLink } = this.state;
    
    return (

      <View style={mstyles.container}>
        { loading ? 
          <ActivityIndicator size="large" style={{marginTop:20}}/>
        :
          <Text>There was an error creating your Stripe account</Text>
        }

      </View>

    );
  }
}
