import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
  Modal,
} from 'react-native';


import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';
import common from '../common';
import FirebaseConsult from '../firebase/firebase_consult.js'

import AgoraUIKit, { PropsInterface, layout } from 'agora-react-uikit';


export default class Screen extends Component {

  constructor(props) {

    super(props);

    this.state = {
      inCall: true,
      showModal: false,
    };
    
    global.consult.status = "in progress";
    common.storeUserData()
    .then(() => FirebaseConsult.updateConsultInfo() )

  }

  handleEndCall = () => {
    this.setState({ showModal: true });
  }

  handleConfirmEndCall = () => {
    global.consult.status = 'ended';
    common.storeUserData()
    .then(() => FirebaseConsult.updateConsultInfo() )
    .then(() => {
      this.setState({ inCall: false, showModal: false });
      this.props.navigation.navigate('C_Consult2');
    });
  }

  handleCancelEndCall = () => {
    this.setState({ showModal: false });
  }

  render() {
    
    const agora = {
      connectionData: {
        appId: '77fcf06390ac4ca685fac7a2b37f3a81',
        channel: global.consult.channel,
        token: global.consult.token,
        layout: layout.grid,
      },
      rtcCallbacks: {
        EndCall: () => {
          this.handleEndCall();
        },
      },
      style: {
        localBtnContainer: {backgroundColor: '#17171a'}
      }
    };

    console.log(agora.connectionData.channel);
    console.log(agora.connectionData.token);
  

    return (
      <View style={{flex:1}} >
        {(this.state.inCall) &&
          <AgoraUIKit rtcProps={agora.connectionData} callbacks={agora.rtcCallbacks} styleProps={agora.style}/>
        }
        <Modal
          visible={this.state.showModal}
          transparent={true}
          animationType='fade'
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>End Consultation</Text>
              <Text style={styles.modalText}>Are you sure you want to end the consultation?</Text>
              <View style={styles.modalButtons}>
                <TouchableOpacity style={styles.modalButton} onPress={this.handleConfirmEndCall}>
                  <Text style={styles.modalButtonText}>Yes</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.modalButton} onPress={this.handleCancelEndCall}>
                  <Text style={styles.modalButtonText}>No</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    );
  }

  static navigationOptions = {
    headerLeft: null,
    headerBackVisible: false,
  };
  
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
  },
  modalTitle: {
    fontFamily: 'nunito',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'nunito',
    fontSize: 16,
    marginBottom: 20,
  },
  modalButtons: {
    flexDirection: 'row',
  },
  modalButton: {
    backgroundColor: '#d02e25',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginHorizontal: 10,
  },
  modalButtonText: {
    fontFamily: 'nunito',
    color: '#fff',
    fontSize: 16,
  },
});
