import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
  Alert,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'
import Messaging from '../messaging.js';
import common from '../common';

import EonnEllipsis from '../components/ellipsis';
import EonnButton from '../components/button';

import { mstyles } from '../constants/MainStyles';


const styles = StyleSheet.create({
  waitingBox: {
    flexDirection:"row",
    maxWidth:200,
    justifyContent:'center',
    alignItems:'center',
    marginTop: 25,
  },
  waitingText: {
    fontSize:20,
    textAlign:'center'
  },
});

export default class Screen extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      lost: false,
    };
    console.log(global.consult);
    FirebaseConsult.watchForBidAccept(this.onAccept, this.onDelete);
  }

  onAccept = (won) => {
    console.log("onAccept");
    if (won){
      global.consult.status = "in progress";
      FirebaseConsult.updateConsultInfo()
      .then (() => {
        common.storeUserData();
        this.props.navigation.navigate('T_Consult1');
      } )
      .catch(error => {
        console.log(error);
        Alert.alert("Error", error.message);
      });
    } else {
      this.setState({ lost: true });
    }
  }

  onDelete = (won) => {
    console.log("doc deleted");
    this.props.navigation.navigate('T_Home');
  }


  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        {this.state.lost
          ? <View>
              <Text style={[mstyles.text,{paddingTop:100, paddingBottom:100}]}>The user has selected another auto tech.</Text>
              <EonnButton
                navigationProps={this.props.navigation}
                screen={'T_Home'}
              >
              Back</EonnButton>
            </View>
          : <View style={{justifyContent:"center", alignItems:"center"}}>
              <Text style={[mstyles.text,{paddingTop:100, paddingBottom:0}]}>Your bid has been submitted.</Text>
              <Text style={[mstyles.text,{paddingTop:0, paddingBottom:100}]}>Waiting on the user to select an auto tech
              <EonnEllipsis style={[mstyles.mfont,styles.waitingText]}/></Text>
              <EonnButton
                onPress={() => {
                  FirebaseConsult.cancelBid(global.bid);
                  this.props.navigation.navigate('T_Home');
                }}
                white={true}
              >
              Cancel Bid</EonnButton>
            </View>
        }
      </ScrollView>
    );
  }
}