import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import EonnButton2 from '../components/button2';
import EonnLink from '../components/link';
import { mstyles } from '../constants/MainStyles';
import { SafeAreaView } from 'react-native-safe-area-context';

const styles = StyleSheet.create({
  logo: {
    width: '100%',
    height: null,
    aspectRatio: 2.27,
    resizeMode: 'contain',
    marginBottom: 12,
  },
  title: {
    fontSize: 30,
    lineHeight: 35,
    maxWidth: 290,
    textAlign: 'center',
    marginTop: 0,
  },
  subtitle: {
    fontSize: 22,
    lineHeight: 25,
    maxWidth: 290,
    textAlign: 'center',
    marginBottom: 18,
  },
  divider: {
    width: 243,
    height: 18,
    marginTop: 15,
    marginBottom: 25,
  },
  features: {
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  feature: {
    flexDirection: 'row',
    marginBottom: 9,
  },
  featureIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  featureText: {
    fontSize: 17,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#f9f9fd',
  },
  content: {
    paddingBottom: 30,
    flexDirection: 'column',
  },
  center: {
    width: 650,
    maxWidth: '100%',
  },
  side: {
    flex: 1,
  },
  bottomLinks: {
    flexDirection: 'row',
  }
});

export default class Screen extends Component {
  
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.side}></View>
        <ImageBackground
          source={require('../assets/images/bg3.jpg')}
          style={styles.center}
        >
          <ScrollView contentContainerStyle={[mstyles.content,mstyles.center]}>
            <Image
              source={require('../assets/images/intro-logo.png')}
              style={styles.logo}
            />
            <Text style={[mstyles.mfont,styles.title]}>Get Paid For Your Time & Expert Advice</Text>
            <Text style={[mstyles.mfont,styles.subtitle]}>As An Automotive Technician</Text>
            <View style={styles.features}>
              <View style={styles.feature}>
                <Image source={require('../assets/images/t_icon1.png')} style={styles.featureIcon} />
                <Text style={[mstyles.mfont,styles.featureText]}>Set Your Rate</Text>
              </View>
              <View style={styles.feature}>
                <Image source={require('../assets/images/t_icon2.png')} style={styles.featureIcon} />
                <Text style={[mstyles.mfont,styles.featureText]}>Get Notified On Jobs</Text>
              </View>
              <View style={styles.feature}>
                <Image source={require('../assets/images/t_icon3.png')} style={styles.featureIcon} />
                <Text style={[mstyles.mfont,styles.featureText]}>Work Remotely, From Home</Text>
              </View>
              <View style={styles.feature}>
                <Image source={require('../assets/images/t_icon4.png')} style={styles.featureIcon} />
                <Text style={[mstyles.mfont,styles.featureText]}>Choose Your Work & Get Paid</Text>
              </View>
            </View>
            <EonnButton2
              navigationProps={this.props.navigation}
              image={require('../assets/images/button1.png')}
              arrowSize={0.7}
              style={{ marginBottom:10 }}
              lines={[
                {text: 'LEARN MORE', size: 25},
              ]}
              screen={'T_Intro2'}
            ></EonnButton2>
            
            {/*}
            <View style={styles.bottomLinks}>
              <EonnLink divider={true}>Log In</EonnLink>
              <EonnLink>Help</EonnLink>
            </View>*/}

          </ScrollView>
        </ImageBackground>
        <View style={styles.side}></View>
      </SafeAreaView>
    );
  }
}