import React, { Component } from 'react';
import { Text, TextInput, StyleSheet, View } from 'react-native';


export default function EonnField(props) {
  
    const [text, changeText] = React.useState(props.value ?? "");

    const styles = StyleSheet.create({
      inputWrapper: {
        maxWidth: '85%',
        margin: 10,
      },
      input: {
        color: '#2e303e',
        fontSize: 18,
        fontFamily: 'nunito',
        lineHeight: 21,
        borderWidth: 1,
        borderColor: (( props.showRequired && !text ) || ( props.showError )) ? '#c62923' : '#9597a3',
        paddingTop: 7,
        paddingRight: 14,
        paddingBottom: 7,
        paddingLeft: 14,
        width: 380,
        maxWidth: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textAlignVertical: props.lines ? 'top' : 'center',
      },
      errorMessage: {
        backgroundColor: '#c62923',
        color: '#ffffff',
        fontSize: 12,
        paddingTop: 3,
        paddingRight: 14,
        paddingBottom: 4,
        paddingLeft: 14,
        marginTop: 0,
        marginRight: 10,
        marginBottom: 0,
        marginLeft: 10,
      },
      label: {
        color: '#2e303e',
        fontSize: 18,
        fontFamily: 'nunito',
        lineHeight: 21,
        marginBottom: 5,
      },
    });

    const {value, showRequired, showError, lines, onChange, children, ...extraProps} = props;
    
    return (

      <View style={styles.inputWrapper} >
        <Text style={[styles.label, props.labelStyle]}>{props.children}</Text>
        <TextInput
          style={[styles.input, props.inputStyle]}
          onChangeText={updatedText => { 
            changeText(updatedText);
            props.onChange?.(updatedText);
          }}
          value={text}
          //placeholder={props.children}
          multiline={props.lines ? true : false}
          numberOfLines={props.lines ?? 1}
          {...extraProps}
        />
        { props.showRequired && !text &&
          <Text style={styles.errorMessage}>This field is required</Text>
        }
      </View>
    );
}

