import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnButton2 from '../components/button2';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';
import common from '../common';

const styles = StyleSheet.create({
  logo: {
    width: '100%',
    height: null,
    aspectRatio: 2.27,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  title: {
    fontSize: 35,
    lineHeight: 35,
    maxWidth: 250,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#f9f9fd',
  },
  content: {
    paddingBottom: 30,
    flexDirection: 'column',
  },
  center: {
    width: 650,
    maxWidth: '100%',
  },
  side: {
    flex: 1,
  }
});

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    console.log("reports page user:",global.user);
  }


  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>

        <EonnTitle>Your consultations</EonnTitle>
        <View style={{width:"100%", paddingHorizontal:80, }}>
          {global.user.consults
            ? global.user.consults.map((consult, index) => 
                <EonnLink
                  style={{flex:1, textAlign:"left", flexDirection:"column", backgroundColor:"#f9f9fd", borderRadius:10}} 
                  onPress={() => this.props.navigation.navigate('C_Report', consult)}
                  viewInterior={true}
                  key={index}
                  >
                  <Text style={{fontSize:14, marginTop:10 }}>{common.convertDate(consult.timeRequested)}</Text>
                  <Text style={{fontSize:22, marginBottom:10 }}>{consult.desc}</Text>
                </EonnLink>
              )
            : <View>
                <Text style={mstyles.text}>Welcome to Auto Teletech!</Text>
                <Text style={mstyles.text}>To speak with an auto technician about your vehicle issue now, click on the button below.</Text>
              </View>
          }
        </View>

        <EonnButton navigationProps={this.props.navigation} screen={'C_Profile1'}>Start a new consult</EonnButton>

      </ScrollView>
    );
  }
}