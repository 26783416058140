import React, { Component } from 'react';
import { Text, Platform, TouchableHighlight, Image, ImageBackground, StyleSheet, View } from 'react-native';

export default class EonnButton extends Component {
  
  static defaultProps = {
    loading: false,
  }

  constructor(props) {
    super(props);
    
    this.styles = StyleSheet.create({
      wrapper: {
        marginTop: 40,
        marginBottom: 40,
        marginLeft: 5,
        marginRight: 5,
        width: 380,
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '85%',
      },
      buttonBackground: {
        width: '100%',
        borderRadius: 5,
        overflow: 'hidden',
      },
      button: {
        backgroundColor: props.white ? 'white' : 'none',
        borderColor: '#d22f25',
        borderWidth: props.white ? 2 : 0,
      },
      textWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 30,
        paddingLeft: 30,
        paddingTop: 14,
        paddingBottom: (Platform.OS === 'web') ? 14 : 8,
      },
      hover: {
        backgroundColor: props.white ? '#f9f1f1' : 'rgba(255, 65, 46, 0.48)',
      },
      text: {
        color: props.white ? '#d22f25' : '#ffffff',
        fontSize: 24,
        fontFamily: 'nunito',
        lineHeight: 25,
        padding: 0,
        flexDirection : "row",
        justifyContent : "flex-end",
      },
      shadow: {
        width: 370,
        height: 4,
        backgroundColor: '#e2e0e0',
        maxWidth: '95%',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        overflow: 'hidden',
      }
    });

    this.state = {
      hover: false,
      style: [this.styles.textWrapper],
    };

  }

  render() {
    
    return (
      <View style={[this.styles.wrapper,this.props.style]}>
        <ImageBackground
          style={this.styles.buttonBackground}
          source={require('../assets/images/buttonbg.png')}
        >
            <TouchableHighlight
              disabled={this.props.loading}
              style={this.styles.button}
              underlayColor={ this.props.white ? '#eeeff3' :'rgba(0,20,106,0.05)'}
              onPress={() => {
                var requirementsMet = true;
                //console.log(this.props.required?.());
                this.props.required?.().forEach(field => {
                  if (!field){
                    this.props.showRequired();

                    requirementsMet = false;
                    console.log("requirements not met");
                  }
                });
                if (requirementsMet){
                  if (this.props.screen){
                    this.props.replace
                    ? this.props.navigationProps.navigate(this.props.screen,this.props.param)
                    : this.props.navigationProps.replace(this.props.screen,this.props.param);
                  } else {
                    this.props.onPress();
                  }
                }}}
              onMouseEnter={() =>
                this.setState({
                  hover: true,
                  style: [this.styles.textWrapper, this.styles.hover],
                })}
              onMouseLeave={() =>
                this.setState({
                  hover: false,
                  style: [this.styles.textWrapper],
                })}
            >
                <View style={this.state.style}>
                    {this.props.lines
                      ? this.props.lines.map((item,index) =>
                        <Text key={index} style={[this.styles.text, {fontSize:item.size ? item.size : 20} ]}>
                          {item.text ? item.text : null}</Text>
                        )
                      : <Text style={this.styles.text}>{this.props.children}</Text>
                    }
                </View>
            </TouchableHighlight>
        </ImageBackground>
        <View style={this.styles.shadow}></View>
      </View>
    );
  }
}

