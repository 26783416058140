import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnStars from '../components/stars';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';

const styles = StyleSheet.create({
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  profileBox: {
    flex: 1,
    paddingLeft: 20,
  },
  profileTitle: {
    fontSize: 24,
    lineHeight: 26,
  },
  profileExp: {
    fontSize: 17,
    lineHeight: 19,
  },
  rate1: {
    fontSize: 20,
    lineHeight: 22,
  },
  rate2: {
    fontSize: 24,
    lineHeight: 26,
  },
  rateDollar: {
    fontSize: 19,
  },
  about: {
    fontSize: 16,
    paddingVertical: 15,
    paddingHorizontal: 35,
  },
  subtitle: {
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 16,
  }
});


export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
    };
    console.log(global.user);
    
  }

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>

        <View style={styles.topRow}>
          <Image source={{uri: global.user.profilePicture}} style={styles.profileImage} />

          <View style={styles.profileBox}>
            <Text style={[mstyles.mfont,styles.profileTitle]}>{global.user.name || ""}</Text>
            <Text style={[mstyles.mfont,styles.profileExp]}>Experience: {global.user.experience || ""} Years</Text>
            <EonnStars stars={4}>15</EonnStars>
          </View>
        </View>
        <Text style={[mstyles.mfont,styles.rate1]}>Consultation Rate</Text>
        <Text style={[mstyles.mfont,styles.rate2]}><Text style={styles.rateDollar}>$</Text>{global.user.rate || ""}</Text>
        <Text style={[mstyles.mfont,styles.about]}>{global.user.about || ""}</Text>
        <Text style={[mstyles.mfont,styles.subtitle]}>Vehicle Specialization</Text>
        {global.user.expertise?.map((type,i) =>
          <Text style={[mstyles.mfont,styles.text]} key={i}>{type}</Text>
        )}
        <Text style={[mstyles.mfont,styles.subtitle]}>Repair Specialization</Text>
        {global.user.specializations?.map((type,i) =>
          <Text style={[mstyles.mfont,styles.text]} key={i}>{type}</Text>
        )}
        <Text style={[mstyles.mfont,styles.subtitle]}>Completed Virtual Consultations</Text>
        <Text style={[mstyles.mfont,styles.text]}>{global.user.completedConsults || 0}</Text>

        <EonnButton
          navigationProps={this.props.navigation}
          onPress={() => {
            console.log(global.user);
            this.props.navigation.navigate("T_Profile7_Stripe");
          }}
          >
          Confirm</EonnButton>
      </ScrollView>
    );
  }
}