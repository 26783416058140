import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';


const styles = StyleSheet.create({
  logo: {
    width: '100%',
    height: null,
    maxWidth: 300,
    aspectRatio: 2.8,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  title: {
    fontSize: 35,
    lineHeight: 35,
    maxWidth: 250,
    textAlign: 'center',
  },
});

export default class Screen extends Component {
  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Complete your profile</EonnTitle>
        <Text style={mstyles.text}>placeholder screen.</Text>
      </ScrollView>
    );
  }
}