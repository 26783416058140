import React from 'react';
import { StyleSheet } from 'react-native';
import Constants from 'expo-constants';


export const mstyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  sContainer: {
    flex: 1,
    backgroundColor: '#f9f9fd',
  },
  sContent: {
    paddingTop: 0,
    paddingBottom: 30,
    flexDirection: 'column',
    maxWidth: 650,
    width: '100%',
    backgroundColor: '#ffffff',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  grid: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 700,
  },
  title: {
    flex:1,
    paddingTop: 5,
    marginTop: 16,
    marginRight: 30,
    marginBottom: 30,
    marginLeft: 30,
    textAlign: 'left',
    fontSize: 30,
    fontFamily: 'nunito',
    lineHeight: 32,
    color:'#242632',
  },
  subtitle: {
    flex:1,
    paddingTop: 5,
    marginTop: 10,
    marginRight: 30,
    marginLeft: 30,
    textAlign: 'left',
    fontSize: 26,
    fontFamily: 'nunito',
    lineHeight: 27,
    color:'#242632'
  },
  subtitle2: {
    flex:1,
    paddingTop: 5,
    marginTop: 10,
    marginRight: 30,
    marginLeft: 30,
    textAlign: 'left',
    fontSize: 24,
    fontFamily: 'nunito',
    lineHeight: 25,
    color:'#242632'
  },
  divider: {
    flex:1,
    height:1,
    backgroundColor: '#c5cbd8',
    marginTop: 20,
    marginBottom: 20,
    borderBottomColor: '#c5cbd8',
    borderBottomWidth:1,
  },
  text: {
    marginTop: 5,
    marginRight: 10,
    marginBottom: 25,
    marginLeft: 10,
    fontSize: 18,
    fontFamily: 'nunito',
    color:'#242632',
    maxWidth: 550,
  },
  mfont: {
    fontFamily: 'nunito',
    color:'#242632',
  },
  link: {
    flex:1,
    marginTop: 5,
    marginRight: 30,
    marginBottom: 5,
    marginLeft: 30,
    fontSize: 18,
    fontFamily: 'nunito',
    textDecorationLine: 'underline',
    color:'#8588a0'
  },
  field: {
    marginTop: 8,
    marginBottom: 8,
  },
  err: {
    flex: 1,
    marginTop: 5,
    marginRight: 30,
    marginBottom: 5,
    marginLeft: 30,
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
    fontSize: 18,
    fontFamily: 'nunito',
    color: 'white',
    backgroundColor: '#e43f2d',
    textAlign: 'center',
  },
});
