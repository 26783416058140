import React, { Component, useState, useEffect } from 'react';
import { Platform, Dimensions, View, Text, Image, ImageBackground, TouchableOpacity, StyleSheet, Alert } from 'react-native';

import { NavigationContainer, CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem, } from '@react-navigation/drawer';
import * as Linking from 'expo-linking';

import FirebaseConsult from '../firebase/firebase_consult.js';
import FirebaseUsers from '../firebase/firebase_users.js';
import common from '../common';

import { mstyles } from '../constants/MainStyles';

import Intro1 from '../screens/C_Intro1';
import C_Profile1 from '../screens/C_Profile1';
import C_Issue1 from '../screens/C_Issue1';
import C_Issue2 from '../screens/C_Issue2';
import C_Issue3 from '../screens/C_Issue3';
import C_Profile2 from '../screens/C_Profile2';
import C_Request0 from '../screens/C_Request0';
import C_Request1 from '../screens/C_Request1';
import C_Request2 from '../screens/C_Request2';
import C_Request3_Stripe from '../screens/C_Request3_Stripe';
import C_Consult1 from '../screens/C_Consult1';
//import C_Consult1 from '../screens/C_Consult1w';
import C_Consult2 from '../screens/C_Consult2';
import C_Consult3 from '../screens/C_Consult3';
import C_Consult4 from '../screens/C_Consult4';
import C_Consult5 from '../screens/C_Consult5';
import C_Consult6 from '../screens/C_Consult6';
import C_Profile3 from '../screens/C_Profile3';
import C_Login from '../screens/C_Login';
import C_SignUp from '../screens/C_SignUp';
import C_Reports from '../screens/C_Reports';
import C_Report from '../screens/C_Report';
import C_MyAccount from '../screens/C_MyAccount';

import T_Intro1 from '../screens/T_Intro1';
import T_Intro2 from '../screens/T_Intro2';
import T_Profile1 from '../screens/T_Profile1';
import T_Profile2 from '../screens/T_Profile2';
import T_Profile3 from '../screens/T_Profile3';
import T_Profile4 from '../screens/T_Profile4';
import T_Profile5 from '../screens/T_Profile5';
import T_Profile6 from '../screens/T_Profile6';
import T_Profile7_Stripe from '../screens/T_Profile7_Stripe';
import T_Home from '../screens/T_Home';
import T_Request1 from '../screens/T_Request1';
import T_Request2 from '../screens/T_Request2';
import T_Consult1 from '../screens/T_Consult1';
//import T_Consult1 from '../screens/T_Consult1w';
import T_Consult2 from '../screens/T_Consult2';
import T_Consult3 from '../screens/T_Consult3';

import I_Select from '../screens/I_Select';

function newConsult(props) {
  const currentScreen = props.route.params?.screen;

  if ( currentScreen === 'C_Request1') {
    FirebaseConsult.cancelConsult()
    .then(() => common.resetConsult())
    .then(() => {
      props.navigation.navigate('Customer', { screen: 'C_Profile1', key: Math.random() });
    });
  } else {
    global.consult = {};
    props.navigation.navigate('Customer', { screen: 'C_Profile1', key: Math.random() });
  }

  return null;
}


function NavDrawer() {
  const navigation = useNavigation();

  return (
    <TouchableOpacity onPress={() => navigation.toggleDrawer()} style={{ padding: 10, paddingLeft: 15 }}>
      <Image source={require('../assets/images/drawer.png')} style={{ width: 45, height: 45 }} />
    </TouchableOpacity>
  );
}

function DrawerContent(props) {
  return (
    global.techSide
    ? <DrawerContentScrollView {...props}>
        <DrawerItem
          label="Privacy Policy"
          onPress={() => Linking.openURL('https://doc-hosting.flycricket.io/eonn-teletech-privacy-policy/471de09a-de14-40c8-be3e-45a7c36ff8f1/privacy')}
        />
        <DrawerItem
          label="My Account"
          onPress={() => props.navigation.navigate('Tech', { screen: 'T_Profile1' })}
        />
        { global.auth.currentUser
          ? <DrawerItem
              label="Sign Out"
              onPress={() => {
                FirebaseUsers.signOut();
                // CommonActions.reset({
                //   index: 1,
                //   routes: [{ name: 'T_Prfoile1' }],       
                // });
                //props.navigation.replace('Tech', { screen: 'T_Prfoile1' });
                props.navigation.closeDrawer();
              }}
            />
          : <DrawerItem
              label="Sign In"
              onPress={() => {
                props.navigation.replace('Tech', { screen: 'T_Profile1' });
                props.navigation.closeDrawer();
              }}
            />
        }
        </DrawerContentScrollView>
    : <DrawerContentScrollView {...props}>
        <DrawerItem
          label="Privacy Policy"
          onPress={() => Linking.openURL('https://doc-hosting.flycricket.io/eonn-teletech-privacy-policy/471de09a-de14-40c8-be3e-45a7c36ff8f1/privacy')}
        />
        <DrawerItem
          label="My Account"
          onPress={() => props.navigation.navigate('Customer', { screen: 'C_MyAccount' })}
        />
        <DrawerItem
          label="New Consult"
          onPress={() => {
            newConsult(props);
            props.navigation.closeDrawer();
          }}
        />
        { global.auth.currentUser
          ? <DrawerItem
              label="View Reports"
              onPress={() => {
                props.navigation.navigate('Customer', { screen: 'C_Reports' });
                props.navigation.closeDrawer();
              }}
            />
          : <DrawerItem
              label="Sign Up"
              onPress={() => {
                props.navigation.navigate('Customer', { screen: 'C_SignUp' });
                props.navigation.closeDrawer();
              }}
            />
        }
        { global.auth.currentUser
          ? <DrawerItem
              label="Sign Out"
              onPress={() => {
                FirebaseUsers.signOut();
                props.navigation.replace('Customer', { screen: 'T_Profile1' });
                props.navigation.closeDrawer();
              }}
            />
          : <DrawerItem
              label="Sign In"
              onPress={() => {
                props.navigation.replace('Customer', { screen: 'C_Login' });
                props.navigation.closeDrawer();
              }}
            />
        }
      </DrawerContentScrollView>
  );
}

function NavBar1(props) {

  const navBarStyles = StyleSheet.create({
    bar: {
      flexDirection: 'row',
      paddingRight: 10,
      alignItems: 'center',
    },
    logo: {
      width: 140,
      height: 50,
      marginLeft: 10,
      marginRight: 30,
    },
    link: {

    },
    text: {
      marginTop: 5,
      marginRight: 30,
      marginBottom: 5,
      fontSize: 18,
      fontFamily: 'nunito',
      color:'#242632',
      maxWidth: 380,
    }
  });

  return (
    <View style={navBarStyles.bar}>
      <TouchableOpacity onPress={() => Linking.openURL('https://www.autoteletech.com/')} >
        <Image source={require('../assets/images/logo.png')} style={navBarStyles.logo} />
      </TouchableOpacity>
      <TouchableOpacity style={navBarStyles.link} onPress={() => Linking.openURL('https://www.autoteletech.com/techs.html')} >
        <Text style={navBarStyles.text}>Techs</Text>
      </TouchableOpacity>
      <TouchableOpacity style={navBarStyles.link} onPress={() => Linking.openURL('https://www.autoteletech.com/contact.html')} >
        <Text style={navBarStyles.text}>Contact Us</Text>
      </TouchableOpacity>
    </View>
  );
}

function NavBar2(props) {

  const navBarStyles = StyleSheet.create({
    bar: {
      flexDirection: 'row',
      paddingRight: 10,
    },
    link: {

    },
    text: {
      marginTop: 5,
      marginRight: 30,
      marginBottom: 5,
      fontSize: 18,
      fontFamily: 'nunito',
      color:'#242632',
      maxWidth: 380,
    }
  });

  return (
    global.auth.currentUser
      ? <View style={navBarStyles.bar}>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => {
              newConsult(props);
            }}
          >
            <Text style={navBarStyles.text}>New Consult</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => props.navigation.navigate('Customer', { screen: 'C_MyAccount' })}
          >
            <Text style={navBarStyles.text}>My Account</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => {
              props.navigation.navigate('C_Reports');
            }}
          >
            <Text style={navBarStyles.text}>View Reports</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => {
              FirebaseUsers.signOut()
              .then(() => props.navigation.navigate('C_Profile1'));
            }}
          >
            <Text style={navBarStyles.text}>Sign Out</Text>
          </TouchableOpacity>
        </View>
      : <View style={navBarStyles.bar}>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => {
              newConsult(props);
            }}
          >
            <Text style={navBarStyles.text}>New Consult</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => {
              props.navigation.navigate('C_SignUp');
            }}
          >
            <Text style={navBarStyles.text}>Sign Up</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => props.navigation.navigate('Customer', { screen: 'C_Login' })}
          >
            <Text style={navBarStyles.text}>Sign In</Text>
          </TouchableOpacity>
        </View>
  );
}

function NavBar2_Tech(props) {

  const navBarStyles = StyleSheet.create({
    bar: {
      flexDirection: 'row',
      paddingRight: 10,
    },
    link: {

    },
    text: {
      marginTop: 5,
      marginRight: 30,
      marginBottom: 5,
      fontSize: 18,
      fontFamily: 'nunito',
      color:'#242632',
      maxWidth: 380,
    }
  });

  return (
    global.auth.currentUser
      ? <View style={navBarStyles.bar}>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => props.navigation.navigate('Tech', { screen: 'T_Profile1' })}
          >
            <Text style={navBarStyles.text}>My Account</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => {
              FirebaseUsers.signOut()
              .then(() => props.navigation.reset({
                index: 0,
                routes: [{ name: 'T_Profile1' }],
              }));
            }}
          >
            <Text style={navBarStyles.text}>Sign Out</Text>
          </TouchableOpacity>
        </View>
      : <View style={navBarStyles.bar}>
          <TouchableOpacity
            style={navBarStyles.link}
            onPress={() => props.navigation.navigate('Tech', { screen: 'T_Profile1' })}
          >
            <Text style={navBarStyles.text}>Sign In</Text>
          </TouchableOpacity>
        </View>
  );
}

function MainHeader(props, back=true, isMobile=true) {

  const options = {
    headerRight: () => <NavDrawer {...props} />,
    headerTitle: () => 
      <TouchableOpacity onPress={() => Linking.openURL('https://www.autoteletech.com/')} >
        <Image source={require('../assets/images/logo.png')} style={{ width: 140, height: 50 }} />
      </TouchableOpacity>,
    headerStyle: {
      borderBottomWidth: 0,
    },
    headerTransparent: false,
    headerTitleAlign: 'center',
  };
  if (!back){
    options.headerLeft = null;
    options.headerBackVisible = false;
  }
  const desktopOptions = {
    headerLeft: () => <NavBar1 {...props} />,
    headerRight: () => global.techSide ? <NavBar2_Tech {...props} /> : <NavBar2 {...props} />,
    headerTitle: "",
    headerStyle: {
      borderBottomWidth: 0,
      paddingLeft: 20,
    },
    headerTransparent: false,
    headerTitleAlign: 'center',
  };

  if (Platform.OS === 'ios') {
    return options;
  } else if (Platform.OS === 'android') {
    return options;
  } else if (Platform.OS === 'web') {
    return isMobile ? options : desktopOptions;
  }

};

class CustomerStack extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: props.loggedIn,
      windowWidth: Dimensions.get('window').width,
    };
    global.techSide = false;
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.handleResize);
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: Dimensions.get('window').width });
  };

  render() {
    const isMobile = this.state.windowWidth <= 768;
    const CNav = createNativeStackNavigator();
    return (
      <CNav.Navigator initialRouteName={global.C_StartScreen || "C_Profile1"}>
        <CNav.Screen name="Intro1" component={Intro1} options={{headerShown:false}}/>
        <CNav.Screen name="C_Profile1" component={C_Profile1} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Issue1" component={C_Issue1} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Issue2" component={C_Issue2} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Issue3" component={C_Issue3} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Profile2" component={C_Profile2} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Request0" component={C_Request0} options={() => MainHeader(this.props,false, isMobile)}/>
        <CNav.Screen name="C_Request1" component={C_Request1} options={() => MainHeader(this.props,false, isMobile)}/>
        <CNav.Screen name="C_Request2" component={C_Request2} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Request3_Stripe" component={C_Request3_Stripe} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Consult1" component={C_Consult1} options={() => MainHeader(this.props,false, isMobile)}/>
        <CNav.Screen name="C_Consult2" component={C_Consult2} options={() => MainHeader(this.props,false, isMobile)}/>
        <CNav.Screen name="C_Consult3" component={C_Consult3} options={() => MainHeader(this.props,false, isMobile)}/>
        <CNav.Screen name="C_Consult4" component={C_Consult4} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Consult5" component={C_Consult5} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Consult6" component={C_Consult6} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Profile3" component={C_Profile3} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Login" component={C_Login} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_SignUp" component={C_SignUp} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Reports" component={C_Reports} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_Report" component={C_Report} options={() => MainHeader(this.props, true, isMobile)}/>
        <CNav.Screen name="C_MyAccount" component={C_MyAccount} options={() => MainHeader(this.props, true, isMobile)}/>
      </CNav.Navigator>
    );
  }
  
}

class TechStack extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: props.loggedIn,
      windowWidth: Dimensions.get('window').width,
    };
    global.techSide = true;
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.handleResize);
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: Dimensions.get('window').width });
  };

  render() {
    const isMobile = this.state.windowWidth <= 768;
    const TNav = createNativeStackNavigator();
    return (
      <TNav.Navigator initialRouteName={global.T_StartScreen || "T_Intro1"}>
        <TNav.Screen name="T_Intro1" component={T_Intro1} options={{headerShown:false}}/>
        <TNav.Screen name="T_Intro2" component={T_Intro2} options={{headerShown:false}}/>
        <TNav.Screen name="T_Profile1" component={T_Profile1} options={() => MainHeader(this.props, true, isMobile)}/>
        <TNav.Screen name="T_Profile2" component={T_Profile2} options={() => MainHeader(this.props, true, isMobile)}/>
        <TNav.Screen name="T_Profile3" component={T_Profile3} options={() => MainHeader(this.props, true, isMobile)}/>
        <TNav.Screen name="T_Profile4" component={T_Profile4} options={() => MainHeader(this.props, true, isMobile)}/>
        <TNav.Screen name="T_Profile5" component={T_Profile5} options={() => MainHeader(this.props, true, isMobile)}/>
        <TNav.Screen name="T_Profile6" component={T_Profile6} options={() => MainHeader(this.props, true, isMobile)}/>
        <TNav.Screen name="T_Profile7_Stripe" component={T_Profile7_Stripe} options={() => MainHeader(this.props, true, isMobile)}/>
        <TNav.Screen name="T_Home" component={T_Home} options={() => MainHeader(this.props, false, isMobile)}/>
        <TNav.Screen name="T_Request1" component={T_Request1} options={() => MainHeader(this.props, true, isMobile)}/>
        <TNav.Screen name="T_Request2" component={T_Request2} options={() => MainHeader(this.props, false, isMobile)}/>
        <TNav.Screen name="T_Consult1" component={T_Consult1} options={() => MainHeader(this.props, false, isMobile)}/>
        <TNav.Screen name="T_Consult2" component={T_Consult2} options={() => MainHeader(this.props, false, isMobile)}/>
        <TNav.Screen name="T_Consult3" component={T_Consult3} options={() => MainHeader(this.props, true, isMobile)}/>
      </TNav.Navigator>
    );
  }
  
}

export default class AppNavigator extends Component {

  constructor(props) {
    super(props);
    console.log("AppNavigator constructor");
    console.log("global.techSide",global.techSide);
  }

  render() {
    const MainDrawerNav = createDrawerNavigator();
    return (
      <NavigationContainer>
        <MainDrawerNav.Navigator
          initialRouteName={ global.techSide ? "Tech" : "Customer" }
          drawerContent={(props) => <DrawerContent {...props} />}
          screenOptions={{
            swipeEnabled: false
           }}
        >
          <MainDrawerNav.Screen name="Select" component={I_Select} options={{headerShown:false}}/>
          <MainDrawerNav.Screen name="Customer" component={CustomerStack} options={{headerShown:false}}/>
          <MainDrawerNav.Screen name="Tech" options={{ headerShown: false }}>
            {(screenProps) => (
              <TechStack {...screenProps} />
            )}
          </MainDrawerNav.Screen>
        </MainDrawerNav.Navigator>
      </NavigationContainer>
    )
  }
}