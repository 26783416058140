import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnIconCheckboxes from '../components/icon_checkboxes';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
      selected: [],
    };
  }
  
  render() {
    const types = [
      { icon: require('../assets/images/specializations/ac1.png'), icon2: require('../assets/images/specializations/ac1b.png'), title: 'AC' },
      { icon: require('../assets/images/specializations/brakes1.png'), icon2: require('../assets/images/specializations/brakes1b.png'), title: 'Brake' },
      { icon: require('../assets/images/specializations/fuel1.png'), icon2: require('../assets/images/specializations/fuel1b.png'), title: 'Fuel' },
      { icon: require('../assets/images/specializations/electronics1.png'), icon2: require('../assets/images/specializations/electronics1b.png'), title: 'Electronics' },
      { icon: require('../assets/images/specializations/electrical1.png'), icon2: require('../assets/images/specializations/electrical1b.png'), title: 'Electrical' },
      { icon: require('../assets/images/specializations/engine1.png'), icon2: require('../assets/images/specializations/engine1b.png'), title: 'Engine' },
      { icon: require('../assets/images/specializations/steering1.png'), icon2: require('../assets/images/specializations/steering1b.png'), title: 'Steering' },
      { icon: require('../assets/images/specializations/suspension1.png'), icon2: require('../assets/images/specializations/suspension1b.png'), title: 'Suspension' },
      { icon: require('../assets/images/specializations/transmission1.png'), icon2: require('../assets/images/specializations/transmission1b.png'), title: 'Transmission' },
    ];


    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Your repair specializations</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <Text style={[mstyles.text,{maxWidth:500}]}>Select the types of repair you specialize in</Text>
        <EonnIconCheckboxes
          options={types}
          selected={this.state.selected}
          setSelected={selected => {
            global.user.specializations = selected;
            this.setState({ selected: selected });
          }}
        />
        <EonnButton
          navigationProps={this.props.navigation}
          onPress={() => {
            console.log(global.user);
            this.props.navigation.navigate("T_Profile6");
          }}
          >
          Next</EonnButton>


      </ScrollView>
    );
  }
}