import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'
import FirebaseUsers from '../firebase/firebase_users.js'
import common from '../common';

import { mstyles } from '../constants/MainStyles';


export default class Screen extends Component {
  
  constructor(props) {
    super(props);
    //global.consult.id = "testconsult";
    FirebaseConsult.watchForReport(this.onReport);
  }

  onReport = (consult) => {
    global.consult.report = consult.report;
    //global.consult.status = "completed";
    if (!global.user.consults) global.user.consults = [];
    global.user.consults.push(global.consult);
    //console.log('consults: ',global.user.consults);
    if (global.auth.currentUser) {
      FirebaseUsers.updateUserInfo();
    }

    this.props.navigation.navigate('C_Consult3');
  }

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <Text style={[mstyles.text,{paddingTop:100, paddingBottom:100}]}>Your virtual consultation has been completed.  You should receive a final report from your tech shortly which will outline their findings and recommendations.</Text>
      </ScrollView>
    );
  }
}