import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import EonnButton2 from '../components/button2';
import EonnLink from '../components/link';
import { mstyles } from '../constants/MainStyles';
import { SafeAreaView } from 'react-native-safe-area-context';

const styles = StyleSheet.create({
  logo: {
    width: '100%',
    height: null,
    aspectRatio: 2.27,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  title: {
    fontSize: 35,
    lineHeight: 35,
    maxWidth: 250,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#f9f9fd',
  },
  content: {
    paddingBottom: 30,
    flexDirection: 'column',
  },
  center: {
    width: 650,
    maxWidth: '100%',
  },
  side: {
    flex: 1,
  }
});

export default class Screen extends Component {
  
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.side}></View>
        <ImageBackground
          source={require('../assets/images/bg3.jpg')}
          style={styles.center}
        >
          <ScrollView contentContainerStyle={[mstyles.content,mstyles.center]}>
            <Image
              source={require('../assets/images/intro-logo.png')}
              style={styles.logo}
            />
            <Text style={[mstyles.title,styles.title]}>Having Car Issues?</Text>
            <EonnButton2
              navigationProps={this.props.navigation}
              image={require('../assets/images/button1.png')}
              style={{marginBottom: 70}}
              lines={[
                {text: 'TALK WITH AN', size: 27},
                {text: 'AUTO TECH NOW', size: 22.6},
              ]}
              screen={'C_Profile1'}
            ></EonnButton2>
            <EonnLink>Log In</EonnLink>
            <EonnLink>Help</EonnLink>
          </ScrollView>
        </ImageBackground>
        <View style={styles.side}></View>
      </SafeAreaView>
    );
  }
}