import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableHighlight,
  Button,
  View,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';


const styles = StyleSheet.create({
  profileButtonWrapper: {
    marginBottom: 20,
  },
  profileButton: {
    borderRadius: 100,
  },
  profileCircle: {
    width: 200,
    height: 200,
    borderRadius: 100,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileButtonText: {
    textAlign: 'center',
    fontSize: 24,
    lineHeight: 26,
    fontFamily: 'nunito',
    color: '#fff',
    marginTop: 15,
  },
  profilePicture: {
    width: 200,
    height: 200,
    borderRadius: 100,
    alignSelf: 'center',
  },
  profileButtonShadow: {
    width: 200,
    height: 200,
    borderRadius: 100,
    backgroundColor: '#e2e0e0',
    position: 'absolute',
    top: 4,
  }
});


export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
      profilePicture: null,
    };
    console.log(global.user);
    
  }

  renderProfilePicture() {
    if (this.state.profilePicture) {
      return (
        <Image
          source={{ uri: this.state.profilePicture }}
          style={styles.profilePicture}
        />
      );
    } else {
      return <View style={styles.greyCircle} />;
    }
  }

  onSelectProfilePicture = async () => {
    try {
      const localUri = await FirebaseUsers.pickImage();
      console.log('Image selected successfully:', localUri);
      this.setState({ profilePicture: localUri });
      global.user.profilePicture = localUri;
    } catch (error) {
      console.log('Error:', error.message);
    }
  }
  /*
  onUploadProfilePicture = async () => {
    try {
      const downloadURL = await FirebaseUsers.uploadProfilePicture(this.state.profilePicture);
      console.log('Image uploaded successfully:', downloadURL);
      global.user.profilePicture = downloadURL;
    } catch (error) {
      console.log('Error:', error.message);
    }
  }*/

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>About you</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        
        <View style={styles.profileButtonWrapper}>
          <View style={styles.profileButtonShadow}></View>
          <TouchableHighlight
              style={styles.profileButton}
              underlayColor={ 'rgba(0, 0, 0, 0.2)' }
              onPress={this.onSelectProfilePicture}
          >
          {this.state.profilePicture
            ? <Image
                source={{ uri: this.state.profilePicture }}
                style={styles.profilePicture}
              />
            : <LinearGradient
                colors={['#ed3d2a', '#d13025']}
                style={styles.profileCircle}
                start={[0, 0.5]}
                end={[1, 0.5]}
                >
                <Text style={styles.profileButtonText}>Select a profile photo</Text>
              </LinearGradient>
          }
          </TouchableHighlight>
        </View>

        <EonnField
          onChange={value => Object.assign(global.user,{ experience:value }) }
          showRequired={this.state.showRequired}
          value={global.user.experience}
          >Years in the business</EonnField>
        <EonnField
          onChange={value => Object.assign(global.user,{ about:value }) }
          value={global.user.about}
          lines={10}
          >About you</EonnField>
          
          
        <EonnButton
          navigationProps={this.props.navigation}
          screen={'T_Profile4'}
          showRequired={() => this.setState({ showRequired: true })}
          required={() => [
            global.user.experience
          ]}
          >
          Next</EonnButton>

      </ScrollView>
    );
  }
}