import React, { Component } from 'react';
import { Text, Platform, TouchableHighlight, Image, ImageBackground, StyleSheet, View } from 'react-native';

export default class EonnButton3 extends Component {
  
  constructor(props) {
    super(props);
    
    this.styles = StyleSheet.create({
      wrapper: {
        marginTop: 0,
        marginBottom: -1,
        marginLeft: 5,
        marginRight: 5,
        width: '100%',
        borderColor: '#ceced2',
        borderRightColor: '#f1f1f4',
        borderLeftColor: '#f1f1f4',
        borderWidth: 1,
      },
      button: {
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingRight: 30,
        paddingLeft: 30,
        paddingTop: 15,
        paddingBottom: (Platform.OS === 'web') ? 15 : 9
      },
      hover: {
        backgroundColor: '#f7f7fa',
      },
      mousedown: {
        backgroundColor: '#ebebf2',
      },
      text: {
        color: '#242428',
        fontSize: 17,
        fontFamily: 'nunito',
        lineHeight: 24,
        padding: 0,
        flexDirection : "row",
        justifyContent : "flex-end",
      },
      buttonText: {
        flex: 1,
        paddingRight: 12,
      },
      arrow: {
        width: 19,
        height: 24,
      },
    });

    this.state = {
      style: [],
    };

  }

  render() {

    return (
          <TouchableHighlight
            style={[this.styles.wrapper, this.props.style ]}
            underlayColor={"#ebebf2"}
            activeOpacity={1}
            onPress={() => 
              this.props.screen
                ? this.props.navigationProps.navigate(this.props.screen,this.props.param)
                : this.props.onPress()
              }
            onMouseEnter={() =>
              this.setState({
                style: [this.styles.hover],
              })}
            onMouseDown={() =>
              this.setState({
                style: [this.styles.mousedown],
              })}
            onMouseLeave={() =>
              this.setState({
                style: [],
              })}
          >
            <View style={[this.styles.button, this.state.style ]}>
              <Text style={this.styles.text}>{this.props.children}</Text>
              <Image
                source={require('../assets/images/arrow1.png')}
                style={this.styles.arrow}
              />
            </View>
        </TouchableHighlight>
    );
  }
}

