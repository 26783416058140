import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  Button,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'
import FirebaseUsers from '../firebase/firebase_users.js'

import EonnStars from '../components/stars';
import EonnTitle from '../components/title';
import EonnEllipsis from '../components/ellipsis';
import EonnButton from '../components/button';
import { mstyles } from '../constants/MainStyles';
import common from '../common';
import Messaging from '../messaging.js';


export default class Screen extends Component {

  constructor(props) {
    super(props);
    
    FirebaseUsers.uploadConsultMedia()
    .then(() => FirebaseConsult.updateConsultInfo() )
    .then(() => Messaging.sendSMS(
      "9843580947",
      "A new issue has been posted to Auto TeleTech: " + global.consult.desc
    ))
    .then((response) => {
      console.log("sms response: ", response);
    })
    .then(() =>  {
      console.log("consult updated");
      console.log("consult: ",global.consult);
      //this.props.navigation.navigate("C_Request1", { key: Math.random() } );
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: 'C_Request1' }],
      });

    });
  }

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <Text style={[mstyles.text,{paddingTop:100, paddingBottom:100}]}>Setting up your consult
        <EonnEllipsis style={[mstyles.mfont]}/></Text>
      </ScrollView>
    );
  }
}