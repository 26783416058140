import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'
import Messaging from '../messaging.js';

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
      emailError: false,
      pwError: false,
      password1: "",
      password2: ""
    };
    console.log(global.user);
    
  }


  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Sign Up</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <Text style={mstyles.text}>By signing up for an Auto Teletech account, you'll be able to access all of your previous consultation reports in one place, and we'll save your vehicle information for future consults.</Text>
        <EonnField
          onChange={value => Object.assign(global.user,{ email:value, emailError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.emailError}
          value={global.user.email}
          textContentType={"emailAddress"}
          autoComplete={"email"}
          inputMode={"email"}
          keyboardType={"email-address"}
          >Email</EonnField>
        <EonnField
          onChange={value => this.setState({ password1: value, pwError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.pwError}
          value={this.state.password1}
          textContentType={"password"}
          secureTextEntry={true}
          >Password</EonnField>
        <EonnField
          onChange={value => this.setState({ password2: value, pwError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.pwError}
          value={this.state.password2}
          textContentType={"password"}
          secureTextEntry={true}
        >Re-Enter Password</EonnField>
        <EonnButton
          loading={this.state.loading}
          navigationProps={this.props.navigation}
          showRequired={() => this.setState({ showRequired: true })}
          required={() => [
            global.user.email,
            this.state.password1
          ]}
          onPress={() => {
            this.setState({ loading: true });
            if (this.state.password1 == this.state.password2){
              console.log(global.user.email);
              console.log(this.state.password1);
              FirebaseUsers.signUp(this.state.password1)
                .then((exists) => FirebaseUsers.updateUserInfo())
                .then(() => Messaging.sendEmail(global.user.email, "Welcome to Auto Teletech", "Welcome to Auto Teletech! Thank you for signing up. We're excited to help you with your vehicle needs.", `
                <img src="https://consult.autoteletech.com/assets/logoHeader.jpg">
                <h1>Welcome to Auto Teletech</h1>
                <p>Thank you for signing up. We're excited to help you with your vehicle needs.</p>
                <p>Best,</p>
                <p>The Auto Teletech Team</p>
                <a href="https://www.autoteletech.com">Auto Teletech</a>
                `))
                .then((response) => {
                  console.log("response: ", response);
                  this.props.navigation.navigate("C_Reports");
                })
                .catch((error) => {
                  
                  //handle different error codes
                  let errorText = "";
                  switch (error.code) {
                    case 'auth/email-already-in-use':
                      errorText = "Email already in use";
                      break;
                    case 'auth/invalid-email':
                      errorText = "Invalid email";
                      break;
                    case 'auth/weak-password':
                      errorText = "Password must be at least 6 characters";
                      break;
                    default:
                      errorText = "Error signing up";
                  }

                  this.setState({
                    err: errorText,
                    pwError: false,
                    emailError: error.code === 'auth/invalid-email' ? true : false,
                  });
                  console.log(error);
                  console.log(global.user);
                  this.setState({ loading: false });
                });
            } else {
              console.log("pw");
              this.setState({
                err: "Password fields don't match",
                pwError: true,
                emailError: false,
                loading: false,
             });
            }
          }}
          >
          Next</EonnButton>
      </ScrollView>
    );
  }
}