import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
      emailError: false,
      pwError: false,
      password1: "",
      password2: ""
    };
    console.log(global.user);
  }


  render() {
    console.log("render");
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Log In</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <EonnField
          onChange={value => Object.assign(global.user,{ email:value, emailError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.emailError}
          value={global.user.email}
          textContentType={"emailAddress"}
          autoComplete={"email"}
          inputMode={"email"}
          keyboardType={"email-address"}
          >Email</EonnField>
        <EonnField
          onChange={value => this.setState({ password1: value, pwError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.pwError}
          value={this.state.password1}
          textContentType={"password"}
          secureTextEntry={true}
          >Password</EonnField>
        {/*<EonnField
          onChange={value => this.setState({ password2: value, pwError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.pwError}
          value={this.state.password2}
          textContentType={"password"}
          secureTextEntry={true}
        >Re-Enter Password</EonnField>*/}
        <EonnButton
          loading={this.state.loading}
          navigationProps={this.props.navigation}
          showRequired={() => this.setState({ showRequired: true })}
          required={() => [
            global.user.email,
            this.state.password1
          ]}
          onPress={() => {
            this.setState({ loading: true });
            //if (this.state.password1 == this.state.password2){
            console.log(global.user.email);
            console.log(this.state.password1);
            FirebaseUsers.isTech(global.user.email)
              .then((isTech) => {
                if (isTech) {
                  return FirebaseUsers.signIn(global.user.email,this.state.password1);
                } else {
                  throw new Error("User is not a technician");
                }
              })
              .then(() => {
                this.setState({ loading: false });
                if (global.user.name == null) {
                  this.props.navigation.navigate("T_Profile2");
                } else if (global.user.experience == null) {
                  this.props.navigation.navigate("T_Profile3");
                } else {
                  this.props.navigation.navigate("T_Home");
                }
              })
              .catch((error) => {
                
                let errorText = "";
                if (error.code === 'auth/invalid-email') {
                  errorText = "Invalid email address";
                } else if (error.code === 'auth/user-not-found') {
                  errorText = "User not found";
                } else if (error.code === 'auth/wrong-password') {
                  errorText = "Incorrect password";
                } else {
                  errorText = "Error signing in";
                }

                this.setState({
                  err: errorText,
                  pwError: false,
                  emailError: error.code === 'auth/invalid-email' ? true : false,
                });
                console.log(error);
                console.log(global.user);
                this.setState({ loading: false });
              });
            // } else {
            //   console.log("pw");
            //   this.setState({
            //     err: "Password fields don't match",
            //     pwError: true,
            //     emailError: false,
            //     loading: false,
            //  });
            // }
          }}
          >
          Next</EonnButton>
      </ScrollView>
    );
  }
}