import React, { useState } from 'react';
import { View, TouchableOpacity, Text, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const CheckboxButton = ({ icon, icon2, title, selected, onPress }) => {
  return (
    <View style={{
      margin: 8,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      }}>
      <TouchableOpacity
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          padding: 8,
          backgroundColor: selected ? '#d22f25' : '#fff',
          borderRadius: 8,
          borderWidth: 1,
          borderColor: selected ? '#d22f25' : '#c5c5d5',
          width: 100,
          textAlign: 'center',
        }}
        onPress={onPress}
      >

          {selected
            ? <Image source={icon2} style={{ width: 92, height: 61 }} />
            : <Image source={icon} style={{ width: 92, height: 61 }} />
          }

          <Text style={{ color: selected ? '#fff' : '#202025', marginTop: 8 }}>{title}</Text>
      </TouchableOpacity>
      <View style={{
        width: 100,
        height: 4,
        backgroundColor: selected ? '#e2e0e0' : '#fff',
        maxWidth: '90%',
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        }}></View>
    </View>
  );
};

const CheckboxGroup = ({ options, selected = [], setSelected }) => {
  const handleSelect = (index) => {
    const newSelected = [...selected];
    if (newSelected.includes(options[index].title)) {
      newSelected.splice(newSelected.indexOf(options[index].title), 1);
    } else {
      newSelected.push(options[index].title);
    }
    setSelected(newSelected);
  };

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
      {options.map((option, index) => (
        <CheckboxButton
          key={index}
          icon={option.icon}
          icon2={option.icon2}
          title={option.title}
          selected={selected.includes(option.title)}
          onPress={() => handleSelect(index)}
        />
      ))}
    </View>
  );
};

const EonnIconCheckboxes = ({ options, selected, setSelected }) => {
  return <CheckboxGroup options={options} selected={selected} setSelected={setSelected} />;
};

export default EonnIconCheckboxes;