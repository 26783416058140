
import React from 'react';

import * as firebase from "firebase/app";
import * as firestore from 'firebase/firestore';
import * as firebase_auth from 'firebase/auth';
import * as firebase_storage from "firebase/storage";

import FirebaseUsers from './firebase_users.js'

/*
import {
  API_KEY,
  AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
} from "@env";
*/

import keys from '../keys.json';


export default class FirebaseSetup {
  
  static async init() {
    
    global.user = global.user || {};
    global.consult = global.consult || {};

    global.app = firebase.initializeApp({
        apiKey: keys.API_KEY,
        authDomain: keys.AUTH_DOMAIN,
        databaseURL: keys.DATABASE_URL,
        projectId: keys.PROJECT_ID,
        storageBucket: keys.STORAGE_BUCKET,
    });

    global.db = firestore.initializeFirestore(global.app,{
      experimentalForceLongPolling: true,
      useFetchStreams: false
    });

    global.storage = firebase_storage.getStorage(global.app);

    global.auth = firebase_auth.getAuth();

    return new Promise((resolve, reject) => {
      firebase_auth.onAuthStateChanged(global.auth, user => {
        console.log("onAuthStateChanged:", user?.uid);
        
        if (user) {
          FirebaseUsers.getUserInfo(user.uid)
          .then((response) => {
            global.user = response;
            resolve(true);
          })
          .catch(reject);

        } else {
          resolve(false);
        }
        
      });
    });

  }
}