import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';


import EonnButton from '../components/button';
import EonnTitle from '../components/title';
import { mstyles } from '../constants/MainStyles';

export default class Screen extends Component {


  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
          <EonnTitle>Web Test Select test</EonnTitle>
          <EonnButton
          onPress={() => this.props.navigation.navigate('Customer', { screen: global.C_StartScreen || "C_Profile1" })}
          >
          Customer Side</EonnButton>
          <EonnButton
          onPress={() => this.props.navigation.navigate('Tech', { screen: global.T_StartScreen || "T_Intro1" })}
          >
          Tech Side</EonnButton>
      </ScrollView>
    );
  }
}