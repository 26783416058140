import React, { Component } from 'react';
import { Text, TouchableHighlight, Image, ImageBackground, StyleSheet, View } from 'react-native';

export default class EonnLink extends Component {
  
  constructor(props) {
    super(props);
    
    this.styles = StyleSheet.create({
      button: {
        paddingRight: props.divider ? 15 : 10,
        paddingLeft: props.divider ? 0 : 10,
        marginRight: props.divider ? 15 : 0,
        borderRightColor: '#2e303e',
        borderRightWidth:  props.divider ? 1 : 0,
      },
      hover: {
        color: '#4e5267',
      },
      text: {
        color: '#2e303e',
        fontSize: 20,
        fontFamily: 'nunito',
        lineHeight: 25,
      },
    });

    this.state = {
      hover: false,
      style: [this.styles.text],
    };

  }

  render() {

    return (
      <TouchableHighlight
        style={this.styles.button}
        onPress={this.props.onPress}
        underlayColor="#F0F0F0"
        onMouseEnter={() =>
          this.setState({
            hover: true,
            style: [this.styles.text, this.styles.hover],
          })}
        onMouseLeave={() =>
          this.setState({
            hover: true,
            style: [this.styles.text],
          })}
      >
        {this.props.viewInterior
          ? <View style={this.state.style}>{this.props.children}</View>
          : <Text style={this.state.style}>{this.props.children}</Text>
        }
      </TouchableHighlight>
    );
  }
}

