
import React from 'react';
import * as firestore from 'firebase/firestore';
import * as firebase_auth from 'firebase/auth';
import * as firebase_storage from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

export default class Messaging {

  static async sendSMS(phoneNumber, message) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
        message: message,
      }),
    };
    return fetch('https://us-central1-eonn-teletech.cloudfunctions.net/sendSMS', options);
  }

  static async sendEmail(to, subject, text, html) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: to,
        subject: subject,
        text: text,
        html: html,
      }),
    };
    return fetch('https://us-central1-eonn-teletech.cloudfunctions.net/sendEmail', options);
  }

}