import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';


export default class Screen extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
    };
    global.consult.report = {};
  }

  render() {
    return ( 
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Consultation Report</EonnTitle>
        <Text style={[mstyles.text]}>Remember, this report is the product that you're selling to your virtual consultation client. Make sure to include as much detail as you can!</Text>
        <Text style={[mstyles.text]}>Possible Causes</Text>
        <EonnField
          onChange={value => Object.assign(global.consult.report,{ cause:value }) }
          showRequired={this.state.showRequired}
          value={global.consult.report?.cause}
          lines={7}
          ></EonnField>
        <Text style={[mstyles.text]}>Recommendation</Text>
        <EonnField
          onChange={value => Object.assign(global.consult.report,{ rec:value }) }
          showRequired={this.state.showRequired}
          value={global.consult.report?.rec}
          lines={7}
          ></EonnField>
        <Text style={[mstyles.text]}>Repair Estimate</Text>
        <EonnField
          onChange={value => Object.assign(global.consult.report,{ repair:value }) }
          showRequired={this.state.showRequired}
          value={global.consult.report?.repair}
          lines={2}
          ></EonnField>
        <Text style={[mstyles.text]}>Who can fix</Text>
        <EonnField
          onChange={value => Object.assign(global.consult.report,{ fix:value }) }
          showRequired={this.state.showRequired}
          value={global.consult.report?.fix}
          lines={2}
          ></EonnField>
        <EonnButton
          loading={this.state.loading}
          navigationProps={this.props.navigation}
          showRequired={() => this.setState({ showRequired: true })}
          required={() => [
            global.consult.report?.cause,
            global.consult.report?.rec,
            global.consult.report?.repair,
            global.consult.report?.fix,
          ]}
          screen={'T_Consult3'}
          >
          Preview Report</EonnButton>
      </ScrollView>
    );
  }
}