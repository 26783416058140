import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';

const EonnPicker = ({ items, value, label, onChange, showError, showRequired, placeholder }) => {
    const [selectedValue, setSelectedValue] = React.useState(value ?? "");
    
    const styles = StyleSheet.create({
      pickerWrapper: {
        maxWidth: '85%',
        margin: 10,
      },
      picker: {
        color: '#2e303e',
        fontSize: 18,
        fontFamily: 'nunito',
        lineHeight: 21,
        borderWidth: 1,
        borderColor: (( showRequired && !selectedValue ) || showError) ? '#c62923' : '#9597a3',
        paddingTop: 7,
        paddingRight: 14,
        paddingBottom: 7,
        paddingLeft: 14,
        width: 380,
        maxWidth: '100%',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
      },
      errorMessage: {
        backgroundColor: '#c62923',
        color: '#ffffff',
        fontSize: 12,
        paddingTop: 3,
        paddingRight: 14,
        paddingBottom: 4,
        paddingLeft: 14,
        marginTop: 0,
        marginRight: 10,
        marginBottom: 0,
        marginLeft: 10,
      },
      label: {
        color: '#2e303e',
        fontSize: 18,
        fontFamily: 'nunito',
        lineHeight: 21,
        marginBottom: 5,
      },
    });

    return (
      <View style={styles.pickerWrapper}>
        <Text style={styles.label}>{label}</Text>
        <Picker
          selectedValue={selectedValue}
          onValueChange={(itemValue) => {
            setSelectedValue(itemValue);
            onChange?.(itemValue);
          }}
          style={styles.picker}
        >
          { placeholder && <Picker.Item label={placeholder} value="" color="grey" /> }
          {items.map((item, index) => (
            <Picker.Item key={index} label={String(item)} value={item} />
          ))}
        </Picker>
        { showRequired && !selectedValue &&
          <Text style={styles.errorMessage}>This field is required</Text>
        }
      </View>
    );
}

export default EonnPicker;