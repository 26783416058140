import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'
import Messaging from '../messaging.js';
import common from '../common';

import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnTitle from '../components/title';
import { mstyles } from '../constants/MainStyles';


export default class Screen extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      err: "",
    };
  }

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
      <EonnTitle>Consultation Report Preview</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <View style={{alignItems:'flex-start', flex:1}}>
         <Text style={mstyles.subtitle}>Possible Causes</Text>
         <Text style={mstyles.text}>{global.consult.report.cause}</Text>
         <View style={mstyles.divider}></View>
         <Text style={mstyles.subtitle}>Recommendation</Text>
         <Text style={mstyles.text}>{global.consult.report.rec}</Text>
         <View style={mstyles.divider}></View>
         <Text style={mstyles.subtitle}>Repair Estimate</Text>
         <Text style={mstyles.text}>{global.consult.report.repair}</Text>
         <View style={mstyles.divider}></View>
         <Text style={mstyles.subtitle}>Who can fix</Text>
         <Text style={mstyles.text}>{global.consult.report.fix}</Text>
         <View style={mstyles.divider}></View>
        </View>
        
        <EonnButton navigationProps={this.props.navigation} screen={'T_Consult2'}>Edit</EonnButton>

        <EonnButton
          loading={this.state.loading}
          navigationProps={this.props.navigation}
          onPress={() => {
            this.setState({ loading: true });
            global.consult.status = 'reportSent';
            global.user.completedConsults = global.user.completedConsults + 1;
            common.storeUserData()
            .then(() => FirebaseConsult.updateConsultInfo() )
            .then(() => FirebaseConsult.completeConsult())
            .then(() => Messaging.sendSMS(global.consult.c_phone, "You've received a new report for your issue: "+global.consult.desc+" from " + global.user.name + ". Visit https://consult.autoteletech.com for more details."))
            .then(() => Messaging.sendEmail(global.user.email, "Consult complete for: "+global.consult.c_name, "Consult complete",`
              <h1>Auto Teletech Consult Complete</h1>
              <h2>Issue:</h2>
              <p>${global.consult.desc}</p>
              <h2>Customer:</h2>
              <p>${global.consult.c_name}</p>
              <h2>Customer Email:</h2>
              <p>${global.consult.c_email}</p>
              <h2>Customer Phone:</h2>
              <p>${global.consult.c_phone}</p>
              <h2>Vehicle:</h2>
              <p>${global.consult.c_vehicle}</p>
              <h2>Amount Paid:</h2>
              <p>$${global.consult.price} + Tip</p>

              <h1>Consult Report:</h1>
              <h2>Possible Causes:</h2>
              <p>${global.consult.report.cause}</p>
              <h2>Recommendation:</h2>
              <p>${global.consult.report.rec}</p>
              <h2>Repair Estimate:</h2>
              <p>${global.consult.report.repair}</p>
              <h2>Who can fix:</h2>
              <p>${global.consult.report.fix}</p>

              <p>If this is your first consult, your payment from Stipe may be delayed as they verify your account. Otherwise, your payment should arrive within 48 hours. The customer may choose to add a tip to your usual rate.</p>
            `))
            .then((response) => {
              console.log("response: ", response);
              return Messaging.sendEmail(global.consult.c_email, "Your consult report for: "+global.consult.desc, "Auto Teletech Report", `
                <h1>Your Auto Teletech report:</h1>
                <h2>Issue:</h2>
                <p>${global.consult.desc}</p>
                <h2>Vehicle:</h2>
                <p>${global.consult.c_vehicle}</p>
                <h2>Report by:</h2>
                <p>${global.consult.t_name}</p>
                <h2>Possible Causes:</h2>
                <p>${global.consult.report.cause}</p>
                <h2>Recommendation:</h2>
                <p>${global.consult.report.rec}</p>
                <h2>Repair Estimate:</h2>
                <p>${global.consult.report.repair}</p>
                <h2>Who can fix:</h2>
                <p>${global.consult.report.fix}</p>
                <p>Thank you for using Auto Teletech!</p>
                <p>Visit us at <a href="https://www.autoteletech.com">www.autoteletech.com</a></p>
              `);
            })
            .then((response) => {
              console.log("response: ", response);
              this.props.navigation.navigate("T_Home");
            })
            .catch((error) => {
              this.setState({ err: error.toString() });
              this.setState({ loading: false });
            });
          }}
          >
          Send Report</EonnButton>

      </ScrollView>
    );
  }
}