import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  Button,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'
import FirebaseUsers from '../firebase/firebase_users.js'
import Messaging from '../messaging.js';

import EonnStars from '../components/stars';
import EonnTitle from '../components/title';
import EonnEllipsis from '../components/ellipsis';
import EonnButton from '../components/button';
import { mstyles } from '../constants/MainStyles';
import common from '../common';

const styles = StyleSheet.create({
  issueBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30,
    paddingBottom: 25,
    borderBottomColor: '#ceced2',
    borderBottomWidth: 1,
    width: '100%',
  },
  issueImage: {
    width: 47,
    height: 47,
    marginBottom: 5,
  },
  issueText: {
    fontSize: 22,
    lineHeight: 24,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 12,
    maxWidth: 200,
  },
  waitingBox: {
    flexDirection:"row",
    maxWidth:200,
    justifyContent:'center',
    alignItems:'center',
    marginTop: 25,
  },
  waitingText: {
    fontSize:20,
    textAlign:'center'
  },
  profileButton: {
    borderBottomColor: '#ceced2',
    borderBottomWidth: 1,
    width: '100%',
  },
  profileButton2: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    justifyContent:'center',
    alignItems:'center',
  },
  profileImage: {
    marginRight: 15,
    height:59,
    width:59,
    borderRadius: 30,
  },
  profileBox: {
    flex: 1,
  },
  profileTitle: {
    fontSize: 23,
    lineHeight: 27,
  },
  profileExp: {
    fontSize: 14,
    lineHeight: 16,
  },
  profilePrice: {
    fontSize: 21,
    lineHeight: 30,
    marginRight: 5,
  },
  profileDollar: {
    fontSize: 16,
  },
  profileArrow: {
    width: 19,
    height: 24,
  }
});

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bids: [],
    };
    console.log("C_Request1");
    
    FirebaseConsult.getBids()
    .then(bids => {
      this.setState(prevState => ({
        bids: bids,
      }));
      FirebaseConsult.watchForBids(this.onBid);
    });
  }

  onBid = (bids) => {

    let previousBids = this.state.bids;

    const changedBid = bids.find((newBid, index) => {
      return !previousBids[index] || newBid.id !== previousBids[index].id;
    });
  
    console.log("bids: ", bids);

    this.setState(prevState => ({
      bids: bids,
    }));

  }

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle
          checkmark={true}
          lines={[
            {text: 'Issue Submitted!', size: 27},
            {text: 'Auto techs are now submitting bids', size: 16},
          ]}
          style={{marginBottom:0}}
        ></EonnTitle>
        <View style={styles.issueBox}>
          <Image source={require('../assets/images/issue1.png')} style={styles.issueImage} />
          <Text style={[styles.text,styles.issueText]}>
            {global.consult.desc}
          </Text>
        </View>
        
        {this.state.bids[0] 
          ? this.state.bids.map((bid,i) =>
              <TouchableHighlight
                key={i}
                onPress={() => {
                  console.log("uid",bid.t_uid);
                  global.bid = bid;
                  this.props.navigation.navigate('C_Request2');
                  // FirebaseUsers.getUserInfo(bid.t_uid)
                  //   .then ( userdata => {
                  //     global.bid = userdata;
                  //     global.bid.token = bid.token;
                  //     global.bid.channel = bid.channel;
                  //     this.props.navigation.navigate('C_Request2');
                  //   })
                }}
                style={styles.profileButton}
                underlayColor={'#e6e6eb'}
              >
                <View style={styles.profileButton2}>
                  <Image source={{uri: bid.t_profilePicture}} style={styles.profileImage} />

                  <View style={styles.profileBox}>
                    <Text style={[mstyles.mfont,styles.profileTitle]}>{bid.t_name}</Text>
                    <Text style={[mstyles.mfont,styles.profileExp]}>Experience: {bid.t_experience} Years</Text>
                    <EonnStars stars={bid.t_stars}>{bid.t_ratings}</EonnStars>
                  </View>
                  <Text style={[mstyles.mfont,styles.profilePrice]}><Text style={styles.profileDollar}>$</Text>{bid.price}</Text>
                  <Image source={require('../assets/images/arrow1.png')} style={styles.profileArrow} />
                </View>
              </TouchableHighlight>
            )
          : <View style={styles.waitingBox}>
              <Text style={[mstyles.mfont,styles.waitingText]}>Waiting for bids
              <EonnEllipsis style={[mstyles.mfont,styles.waitingText]}/></Text>
            </View>
        }
        
        <EonnButton
          navigationProps={this.props.navigation}
          white={true} 
          onPress={() => {
            FirebaseConsult.cancelConsult()
            .then(() => {
              console.log("Consult cancelled");
            })
            .then(common.resetConsult())
            .then(() => {
              if (global.auth.currentUser) {
                this.props.navigation.navigate('C_Reports');
              } else {
                global.user = {};
                this.props.navigation.navigate('C_Profile1');
              }
            });
          }}
        >
        Cancel Request</EonnButton>

      </ScrollView>
    );
  }
}