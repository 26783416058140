import React, { Component } from 'react';
import { View, Image, TouchableHighlight } from 'react-native';

import { mstyles } from '../constants/MainStyles';

export default class RateStars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 5,
      hoverRating: 0,
    };
  }

  setRating(rating) {
    this.setState({ rating });
    if (this.props.onRatingChange) {
      this.props.onRatingChange(rating);
    }
  }

  handleMouseEnter(index) {
    this.setState({ hoverRating: index + 1 });
  }

  handleMouseLeave() {
    this.setState({ hoverRating: 0 });
  }

  render() {
    const { rating, hoverRating } = this.state;
    const emptyStar = require('../assets/images/star2-empty.png');
    const filledStar = require('../assets/images/star2.png');
    const selectStar = require('../assets/images/star2-over.png');
  
    return (
      <View style={{ flexDirection: 'row', marginTop: 3 }}>
        {[...Array(5)].map((e, i) => (
          <TouchableHighlight
            activeOpacity={1}
            underlayColor="transparent"
            key={i}
            onMouseEnter={() => this.handleMouseEnter(i)}
            onMouseLeave={this.handleMouseLeave.bind(this)}
            onPress={() => this.setRating(i + 1)}
          >
            <Image
              source={
                hoverRating > 0
                  ? i < hoverRating
                    ? selectStar
                    : emptyStar
                  : i < rating
                  ? filledStar
                  : emptyStar
              }
              style={{ width: 35, height: 33, marginRight: 2 }}
            />
          </TouchableHighlight>
        ))}
      </View>
    );
  }
}
