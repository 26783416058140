import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import EonnButton2 from '../components/button2';
import EonnLink from '../components/link';
import { mstyles } from '../constants/MainStyles';
import { SafeAreaView } from 'react-native-safe-area-context';

const styles = StyleSheet.create({
  logo: {
    width: 140,
    height: 50,
    marginTop: 12,
    marginBottom: 10,
  },
  text: {
    textAlign: 'center',
  },
  divider: {
    width: 243,
    height: 18,
    marginTop: 20,
    marginBottom: 35,
  },
  features: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  feature: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  featureIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  featureTitle: {
    fontSize: 22,
    lineHeight: 26,
    maxWidth: 250,
  },
  featureText: {
    fontSize: 17,
    maxWidth: 250,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#f9f9fd',
  },
  content: {
    paddingBottom: 30,
    flexDirection: 'column',
  },
  center: {
    width: 650,
    maxWidth: '100%',
  },
  side: {
    flex: 1,
  },
  bottomLinks: {
    flexDirection: 'row',
  }
});

export default class Screen extends Component {
  
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.side}></View>
        <ImageBackground
          source={require('../assets/images/bg3.jpg')}
          style={styles.center}
        >
          <ScrollView contentContainerStyle={[mstyles.content,mstyles.center]}>
            <Image
              source={require('../assets/images/logo.png')}
              style={styles.logo}
            />
            <Text style={[mstyles.text,styles.text]}>Auto Teletech is a freelance platform for experienced auto technicians to make extra income as automotive consultants. You provide expert advice to customers on vehicle diagnostics and repairs.</Text>
            <Image source={require('../assets/images/divider.png')} style={styles.divider} />
            <View style={styles.features}>
              <View style={styles.feature}>
                <Image source={require('../assets/images/t_icon1.png')} style={styles.featureIcon} />
                <View>
                  <Text style={[mstyles.mfont,styles.featureTitle]}>Set Your Rate</Text>
                  <Text style={[mstyles.mfont,styles.featureText]}>You choose how much to charge customers for your expert advice on car issues during consultations.</Text>
                </View>
              </View>
              <View style={styles.feature}>
                <Image source={require('../assets/images/t_icon2.png')} style={styles.featureIcon} />
                <View>
                  <Text style={[mstyles.mfont,styles.featureTitle]}>Get Notified On Jobs</Text>
                  <Text style={[mstyles.mfont,styles.featureText]}>Auto Teletech notifies you when there is a new customer request. Think of it as work dispatch.</Text>
                </View>
              </View>
              <View style={styles.feature}>
                <Image source={require('../assets/images/t_icon3.png')} style={styles.featureIcon} />
                <View>
                  <Text style={[mstyles.mfont,styles.featureTitle]}>Work Remotely, From Home</Text>
                  <Text style={[mstyles.mfont,styles.featureText]}>You don't need to touch a car or your tools. With the Auto Teletech app and your experience working on cars, you can work from anywhere.</Text>
                </View>
              </View>
              <View style={styles.feature}>
                <Image source={require('../assets/images/t_icon4.png')} style={styles.featureIcon} />
                <View>
                  <Text style={[mstyles.mfont,styles.featureTitle]}>Choose Your Work & Get Paid</Text>
                  <Text style={[mstyles.mfont,styles.featureText]}>You are your own boss now, so you decide the jobs you pick. You get paid at the end of your consultation.</Text>
                </View>
              </View>
            </View>
            <EonnButton2
              navigationProps={this.props.navigation}
              image={require('../assets/images/button1.png')}
              arrowSize={0.7}
              style={{ marginBottom:60 }}
              lines={[
                {text: 'GET STARTED', size: 25},
              ]}
              screen={'T_Profile1'}
            ></EonnButton2>
          </ScrollView>
        </ImageBackground>
        <View style={styles.side}></View>
      </SafeAreaView>
    );
  }
}