import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
      emailError: false,
      pwError: false,
      password1: "",
      password2: ""
    };
    console.log(global.user);
  }


  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Sign In</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <EonnField
          onChange={value => Object.assign(global.user,{ email:value, emailError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.emailError}
          value={global.user.email}
          textContentType={"emailAddress"}
          autoComplete={"email"}
          inputMode={"email"}
          keyboardType={"email-address"}
          >Email</EonnField>
        <EonnField
          onChange={value => this.setState({ password1: value, pwError:false }) }
          showRequired={this.state.showRequired}
          showError={this.state.pwError}
          value={this.state.password1}
          textContentType={"password"}
          secureTextEntry={true}
          >Password</EonnField>
        <EonnButton
          loading={this.state.loading}
          navigationProps={this.props.navigation}
          showRequired={() => this.setState({ showRequired: true })}
          required={() => [
            global.user.email,
            this.state.password1
          ]}
          onPress={() => {
            this.setState({ loading: true });
            //check whether fields have been auto-filled
            
            console.log(global.user.email);
            console.log(this.state.password1);
            FirebaseUsers.signIn(global.user.email,this.state.password1)
              .then(() => {
                this.props.navigation.navigate("C_Reports");
              })
              .catch((error) => {
                this.setState({
                  err: error.code === 'auth/invalid-email' ? "Please enter a valid email address" : error.toString(),
                  pwError: false,
                  emailError: error.code === 'auth/invalid-email' ? true : false,
                });
                console.log(error);
                console.log(global.user);
                this.setState({ loading: false });
              });
          }}
          >
          Next</EonnButton>
      </ScrollView>
    );
  }
}