import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import EonnButton from '../components/button';
import EonnRateStars from '../components/rateStars';
import EonnTitle from '../components/title';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';


const styles = StyleSheet.create({
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: '#ccc',
  },
  profileBox: {
    flex: 1,
    paddingLeft: 20,
  },
  profileTitle: {
    fontSize: 24,
    lineHeight: 26,
  },
  profileExp: {
    fontSize: 17,
    lineHeight: 19,
  },
});

export default class Screen extends Component {

  constructor(props) {
    super(props);
    console.log("global.consult: ");
    console.log(global.consult);
  }


  handleRatingChange(rating) {
    global.consult.rating = rating;
    console.log("Rating: " + rating);
  }
  
  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Review Your Tech</EonnTitle>
        <View style={styles.topRow}>
          <Image source={{uri: global.consult.t_profilePicture}} style={styles.profileImage} />

          <View style={styles.profileBox}>
            <Text style={[mstyles.mfont,styles.profileTitle]}>{global.consult.t_name || "Auto Tech"}</Text>
            <Text style={[mstyles.mfont,styles.profileExp]}>Experience: {global.consult.t_experience || "?"} Years</Text>
          </View>
        </View>

        <Text style={[mstyles.text, { marginBottom:0, marginTop:20 }]}>How would you rate this tech?</Text>
        <EonnRateStars onRatingChange={(rating) => this.handleRatingChange(rating)} />

        <Text style={[mstyles.text, { marginBottom:0, marginTop:20 }]}>Write a review (optional)</Text>
        <EonnField
          onChange={value => Object.assign(global.consult,{ t_review:value }) }
          value={global.consult.t_review}
          lines={10}
          ></EonnField>


        <EonnButton navigationProps={this.props.navigation} screen={'C_Consult6'}>Send Review</EonnButton>
      </ScrollView>
    );
  }
}