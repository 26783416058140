import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import EonnButton3 from '../components/button3';
import EonnButton from '../components/button';
import EonnTitle from '../components/title';
import { mstyles } from '../constants/MainStyles';


export default class Screen extends Component {
  render() {
    const options = [
      ["A/C Not Working",
      "Blowing Hot Air",
      "Defroster Not Working",
      "Not Blowing Air",
      "Not Coming On",
      "Not Cooling",
      "Not Heating",
      "Smelling",
      "I'm Not Sure",
      "Other"],
      
      ["Brake Fluid Leaks",
      "Brakes Sticking",
      "Making Noise",
      "No Brake",
      "Parking Brake Not Working",
      "Pedal Feels Funny",
      "Smoke Coming From Brakes",
      "Vibrates When Applied",
      "I'm Not Sure",
      "Other"],
      
      ["Bumpy Ride",
      "Entered Wrong Fuel",
      "Hard To Turn Steering",
      "Horn Not Working",
      "No Power Steering",
      "Poor Gas Mileage",
      "Suspension Issue",
      "Suspension Noise",
      "Tire Issues",
      "Transmission Issue",
      "Vibrates When I Step Brakes",
      "I'm Not Sure",
      "Other"],
      
      ["Check Engine Light",
      "Coolant Leak",
      "Engine Misfires",
      "Engine Oil Leaks",
      "Noise",
      "Smell",
      "Smoke",
      "I'm Not Sure",
      "Other"],
      
      ["Car Wont Start",
      "Cranks For Long",
      "Dashboard Lights Doesn't Come On",
      "Dashboard Lights Flashes",
      "Dies After Starting",
      "Dies While Driving",
      "Electrical Related",
      "Key Wont Start Car Anymore",
      "I'm Not Sure",
      "Other"],
      
      ["Airbag Light",
      "All My Warning Lights Are On",
      "Battery Light",
      "Brake Light",
      "Check Engine Light",
      "Headlamp Lights",
      "Lightning Light",
      "Steering Light",
      "Tire Light",
      "I'm Not Sure",
      "Other"],
      
      ["Bluetooth Issues",
      "Phone Connection Issues",
      "Car Key & Entry Issues",
      "Cruise Control Issues",
      "Power Seat Not Working",
      "Remote Start Issues",
      "Smart Radio Issues",
      "Steering Button Issues",
      "Stereo Issues",
      "I'm Not Sure",
      "Other"]];

    console.log(this.props.route.params);
    console.log("test123");
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
      <EonnTitle>Talk to an Auto Tech</EonnTitle>
      <Text style={mstyles.text}>What seems to be the issue? Please select a category below</Text>
        {options[this.props.route.params.issuenumber].map((option,i) => {
          return (
          <EonnButton3
            key={i}
            onPress={() => {
              global.consult.desc=option;
              this.props.navigation.navigate('C_Issue3',{issuenumber:this.props.route.params.issuenumber});
            }}
          >{option}</EonnButton3>  
          )
        })}
        <EonnButton
          navigationProps={this.props.navigation}
          screen={'C_Issue1'}
          white={true}
          >
          Back</EonnButton>
      </ScrollView>
    );
  }
}