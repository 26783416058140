import React, { Component } from 'react';
import { Text, Platform, TouchableHighlight, Image, ImageBackground, StyleSheet, View } from 'react-native';

export default class EonnButton2 extends Component {

  static defaultProps = {
    loading: false,
  }

  constructor(props) {
    super(props);

    let paddingOffset = props.lines.length > 1 ? 0 + (props.paddingOffset || 0) : 15 + (props.paddingOffset || 0);
    let arrowSize = props.arrowSize || 1;
    
    this.styles = StyleSheet.create({
      wrapper: {
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 5,
        marginRight: 5,
      },
      button: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        paddingRight: 46 - (props.paddingOffset || 0),
        paddingLeft: 46 - (props.paddingOffset || 0),
        paddingTop: 35 - paddingOffset,
        paddingBottom: ((Platform.OS === 'web') ? 35 : 33) - paddingOffset,
      },
      buttonC: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 130,
        height: 100,
        paddingTop: 50,
        overflow: 'hidden',
      },
      hover: {
        backgroundColor: 'rgba(191, 183, 249, 0.1)',
      },
      mousedown: {
        backgroundColor: 'rgba(191, 183, 249, 0.15)',
      },
      text: {
        color: '#ffffff',
        fontSize: 15,
        fontFamily: 'nunito',
        lineHeight: 24,
        padding: 0,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        includeFontPadding: false,
        textAlignVertical: 'center',
      },
      buttonBackground: {
        borderRadius: 40,
        overflow: 'hidden',
        padding: 0,
        width: 'auto',
        maxWidth: 380,
      },
      buttonBackgroundImage: {
        flex: 1,
      },
      buttonText: {
        paddingRight: 12,
        paddingTop: 3,
        overflow: 'hidden',
      },
      arrow: {
        width: 25 * arrowSize,
        height: 36 * arrowSize,
        top: (Platform.OS === 'web') ? 0 : -2
      },
    });

    this.state = {
      style: [],
    };

  }

  render() {

    return (
          <TouchableHighlight
            disabled={this.props.loading}
            style={[this.styles.wrapper, this.styles.buttonBackground, this.props.style ]}
            activeOpacity={1}
            onPress={() => 
              this.props.screen
                ? this.props.navigationProps.navigate(this.props.screen,this.props.param)
                : this.props.onPress()
              }
            onMouseEnter={() =>
              this.setState({
                style: [this.styles.hover],
              })}
              onMouseDown={() =>
                this.setState({
                  style: [this.styles.mousedown],
                })}
              onMouseLeave={() =>
                this.setState({
                  style: [],
                })}
          >
            <ImageBackground
              style={[]}
              imageStyle={this.styles.buttonBackgroundImage}
              source={require('../assets/images/bg-button.jpg')}
            >
              <View style={[this.styles.button, this.state.style ]}>
                <View style={this.styles.buttonText}>
                    {this.props.lines
                      ? this.props.lines.map((item,index) =>
                        <Text
                          key={index}
                          style={[this.styles.text, {
                            fontSize:item.size ? item.size : 20,
                            height:item.size ? item.size : 20,
                            lineHeight:item.size ? item.size+2 : 22,
                            } ]}>
                          {item.text ? item.text : null}</Text>
                        )
                      : <Text style={this.styles.text}>{this.props.children}</Text>
                    }
                </View>
                <Image
                  source={require('../assets/images/arrow2.png')}
                  style={this.styles.arrow}
                />
              </View>
          </ImageBackground>
        </TouchableHighlight>
    );
  }
}

