import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class common {

  static timeDifference(timeCode) {
    const currentTime = new Date();
    const pastTime = new Date(timeCode);
    const timeDifferenceInSeconds = (currentTime - pastTime) / 1000;
  
    if (timeDifferenceInSeconds < 60) {
      return `${Math.floor(timeDifferenceInSeconds)} second${Math.floor(timeDifferenceInSeconds) === 1 ? '' : 's'} ago`;
    } else if (timeDifferenceInSeconds / 60 < 60) {
      return `${Math.floor(timeDifferenceInSeconds / 60)} minute${Math.floor(timeDifferenceInSeconds / 60) === 1 ? '' : 's'} ago`;
    } else if (timeDifferenceInSeconds / (60 * 60) < 24) {
      return `${Math.floor(timeDifferenceInSeconds / (60 * 60))} hour${Math.floor(timeDifferenceInSeconds / (60 * 60)) === 1 ? '' : 's'} ago`;
    } else {
      return `${Math.floor(timeDifferenceInSeconds / (60 * 60 * 24))} day${Math.floor(timeDifferenceInSeconds / (60 * 60 * 24)) === 1 ? '' : 's'} ago`;
    }
  };

  static randomID(){
    return Math.floor(Math.random() * Math.pow(34, 20)).toString(34).padStart(20, '0').slice(0, 20);
  }

  static async getUserData (){
    try {
      const jsonValue = await AsyncStorage.getItem('@userData');
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
      console.log("AsyncStorage error",e);
      return null;
    }
  }


  static async storeUserData (){
    try {
      const userData = {
        user: global.user,
        consult: global.consult,
        bid: global.bid,
      }
      await AsyncStorage.setItem('@userData', JSON.stringify(userData));
      console.log("AsyncStorage set");
      return true;
    } catch (e) {
      console.log("AsyncStorage error",e);
      return false;
    }
  }

  static async resetConsult(){
    try {
      await AsyncStorage.removeItem('@userData');
      global.consult = {};
      global.C_StartScreen = "C_Profile1";
      return true;
    } catch(e) {
      console.log("AsyncStorage error",e);
      return false;
    }
  }

  static async newConsult(){

    global.consult.id = common.randomID();
    global.consult.c_name = global.user.name;
    global.consult.c_phone = global.user.phone;
    global.consult.c_email = global.user.email;
    global.consult.c_vehicle = global.user.vehicle.year + " " + global.user.vehicle.make + " " + global.user.vehicle.model;
    global.consult.c_miles = global.user.vehicle.mileage;
    global.consult.media = [];
    
    global.consult.timeRequested = new Date().toISOString();
    global.consult.status = "requested";

    common.storeUserData()
    .then(() => { 
      return true;
    }
    ).catch((error) => {
      console.log(error);
      throw error;
    } );

  }

  static convertDate(dateString) {
    // Create a Date object from the string
    var date = new Date(dateString);

    // Get the date parts
    var month = date.getMonth() + 1;  // Months are 0-based in JavaScript
    var day = date.getDate();
    var year = date.getFullYear();

    // Get the time parts
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format and prepend 0 to single-digit minutes
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;

    // Construct the formatted date/time
    var formatted = month + '/' + day + '/' + year + ' ' + hours + ':' + minutes + ' ' + ampm;
    return formatted;
  }

  static async newAgoraToken(channelName) {
    //Generate Agora video call Token
    console.log("newAgoraToken", channelName);

    return fetch(`https://us-central1-eonn-teletech.cloudfunctions.net/generateAgoraToken?channelName=${channelName}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const token = data.token;
        console.log("token:",token);
        return token;
      })
      .catch((error) => {
        console.error('newAgoraToken Error:', error);
        throw error;
      });
  }



  static async handlePayment (paymentAmount) {
    console.log("handleFinalPayment");
    console.log("paymentMethodId: ", global.consult.paymentMethodId);
    console.log("customerId: ", global.consult.customerId);
    console.log("paymentAmount: ", paymentAmount);

    if (paymentAmount <= 0.5) {
      return;
    }
  
    const response = await fetch('https://us-central1-eonn-teletech.cloudfunctions.net/createFinalPaymentIntent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: paymentAmount,
        customerID: global.consult.customerId,
        paymentMethodId: global.consult.paymentMethodId,
        connectedAccountId: global.consult.t_stripeId,
        email: global.user.email,
      }),
    });

    if (!response.ok) {
      console.log("response not ok");
      return;
    }

    const { paymentIntentId, error } = await response.json();

    if (error) {
      console.log('[error]', error);
      return;
    } else if (paymentIntentId) {
      console.log('[paymentIntentId]', paymentIntentId);
      return paymentIntentId;
    }

  };

}
