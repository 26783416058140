import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import EonnButton3 from '../components/button3';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import EonnTitle from '../components/title';
import { mstyles } from '../constants/MainStyles';

export default class Screen extends Component {
  render() {
    const options = [
      "AC",
      "Brakes",
      "Driving",
      "Engine",
      "Starting",
      "Warning Lights",
      "Other",
    ];
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
      <EonnTitle>Talk to an Auto Tech</EonnTitle>
        <Text style={mstyles.text}>What seems to be the issue? Please select a category below</Text>
        {options.map((option,i) => {
          return (
          <EonnButton3
            key={i}
            navigationProps={this.props.navigation}
            screen={'C_Issue2'}
            param={{issuenumber : i}}
          >{option}</EonnButton3>
          )
        })}
        <EonnButton
          navigationProps={this.props.navigation}
          screen={'C_Profile1'}
          white={true}
          >
          Back</EonnButton>
      </ScrollView>
    );
  }
}