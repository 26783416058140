import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';
import common from '../common';

const styles = StyleSheet.create({
  info: {
    marginBottom: 30,
  },
  infoTitle: {
    fontSize: 16,
    marginBottom: 0,
  }

});

export default class Screen extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
    };
    global.consult.report = {};
    //log props passed from previous screen
    console.log('props.route.params',props.route.params);

  }

  render() {
    const consult = this.props.route.params;
    return ( 
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Consultation Report</EonnTitle>
        <Text style={[mstyles.text,{marginBottom:0}]}>{common.convertDate(consult.timeRequested)}</Text>
        <Text style={[mstyles.subtitle,{marginTop:0,marginBottom:20}]}>{consult.desc}</Text>
        <Text style={[mstyles.text,styles.infoTitle]}>Vehicle:</Text>
        <Text style={[mstyles.text,styles.info]}>{consult.c_vehicle}</Text>
        <Text style={[mstyles.text,styles.infoTitle]}>Report by:</Text>
        <Text style={[mstyles.text,styles.info]}>{consult.t_name}</Text>
        <Text style={[mstyles.text,styles.infoTitle]}>Possible Causes:</Text>
        <Text style={[mstyles.text,styles.info]}>{consult.report.cause}</Text>
        <Text style={[mstyles.text,styles.infoTitle]}>Recommendation:</Text>
        <Text style={[mstyles.text,styles.info]}>{consult.report.rec}</Text>
        <Text style={[mstyles.text,styles.infoTitle]}>Repair Estimate:</Text>
        <Text style={[mstyles.text,styles.info]}>{consult.report.repair}</Text>
        <Text style={[mstyles.text,styles.infoTitle]}>Who can fix:</Text>
        <Text style={[mstyles.text,styles.info]}>{consult.report.fix}</Text>
        <EonnButton
          navigationProps={this.props.navigation}
          screen={'C_Reports'}
          >
          Back</EonnButton>
      </ScrollView>
    );
  }
}