import React from 'react';
import * as firestore from 'firebase/firestore';
import * as firebase_auth from 'firebase/auth';
import * as firebase_storage from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

export default class FirebaseUsers {

  static async signUp(pw) {
    if (global.user.email === '' || pw === '') {
      console.log('Please enter an email and password.');
      throw new Error('Please enter an email and password.');
    }
    console.log("signing up");
  
    try {
      let { user } = await firebase_auth.createUserWithEmailAndPassword(global.auth, global.user.email, pw);
      global.user.uid = user.uid;
      return true;
    } catch (error) {
      // if (error.code === 'auth/email-already-in-use') {
      //   console.log('User already exists');
      //   try {
      //     let { user } = await firebase_auth.signInWithEmailAndPassword(global.auth, global.user.email, pw);
      //     global.user.uid = user.uid;
      //     console.log("logged in");
      //     return false;
      //   } catch (error) {
      //     throw error;
      //   }
      // }
      console.error('Error creating user:', error);
      throw error;
    }
  }

  
  static async signUpGoogle(info,pw) {
    const provider = new firebase_auth.GoogleAuthProvider();
    try {
        result = firebase_auth.signInWithPopup(global.auth, provider);
        const credential = firebase_auth.GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        console.log(user);
    } catch(error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = firebase_auth.GoogleAuthProvider.credentialFromError(error);
        // ...
    }
  }

  static async signIn(email, pw) {
    if (email === '' || pw === '') {
      console.log('Please enter an email and password.');
      throw new Error('Please enter an email and password.');
    }
    
    email = email.toLowerCase();
  
    try {
      let { user } = await firebase_auth.signInWithEmailAndPassword(global.auth, email, pw);
      console.log("logged in: ", user.uid);
      global.user = await FirebaseUsers.getUserInfo(user.uid);
      if (global.user == null) {
        console.log("user does not exist in database");
        global.user = { uid:user.uid };
        console.log(global.user);
        FirebaseUsers.updateUserInfo();
      }
      console.log("updated user info");
      return true;
    } catch (error) {
      //handle firebase auth errors
      if (error.code === 'auth/user-not-found') {
        console.log('user not found');
        throw new Error('User not found. Please try again or contact Auto Teletech support to create a new auto tech account.');
      } else if (error.code === 'auth/wrong-password') {
        console.log('Incorrect password');
        throw new Error('Wrong password');
      }

      console.error('Error signing in:', error);
      throw error;
    }
  }

  static async isTech(email) {
    if (email === '') {
      console.log('Please enter an email.');
      throw new Error('Please enter an email.');
    }
  
    email = email.toLowerCase();
  
    try {
      //let user = await firebase_auth.getUserByEmail(global.auth, email);
      //console.log(user.uid);
      //let userinfo = await FirebaseUsers.getUserInfo(user.uid);
      //console.log(userinfo);

      //search users for email

      const q = firestore.query(firestore.collection(global.db, "users"), firestore.where("email", "==", email));
      const querySnapshot = await firestore.getDocs(q);
      let userinfo = null;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        userinfo = doc.data();
      });
      console.log(userinfo);
  

      if (userinfo == null) {
        console.log("user does not exist in database");
        throw new Error('User not found. Please try again or contact Auto Teletech support to create a new auto tech account.');
      }
      if (userinfo.isTech == null) {
        console.log("user is not a tech");
        return false;
      }
      if (userinfo.isTech == true) {
        console.log("user is a tech");
        return true;
      }
    } catch (error) {
      console.error('Error getting user ID:', error);
      throw error;
    }
  }

  //send password reset email
  static async sendPasswordResetEmail(email) {
    if (email === '') {
      console.log('Please enter an email.');
      throw new Error('Please enter an email.');
    }
  
    email = email.toLowerCase();
  
    try {
      await firebase_auth.sendPasswordResetEmail(global.auth, email);
      console.log('Password reset email sent');
      return true;
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  }

  //sign out and clear user data
  static async signOut() {
    try {
      await firebase_auth.signOut(global.auth);
      global.user = {
        uid: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        profilePicture: '',
      };
      console.log('Signed out');
      return true;
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  }

  static async deleteUser() {
    if (global.user.uid == '') {
      console.log('Please login first.');
      throw new Error('Please login first.');
    }
  
    try {
      await firebase_auth.deleteUser(global.auth, global.user.uid);
      console.log('User deleted');
      return true;
    } catch (error) {
      console.log('Error deleting user:', error);
      throw error;
    }
  }

  static async updateUserInfo() {
    if (global.user.uid == '') {
      console.log('Please login first.');
      throw new Error('Please login first.');
    }
  
    try {
      await firestore.setDoc(firestore.doc(global.db, "users", global.user.uid), global.user);
      console.log('User data updated');
      return true;
    } catch (error) {
      console.log('Error updating user data:', error);
      throw error;
    }
  }

  static async getUserInfo(uid) {
    console.log('getUserInfo');
    try {
      const docRef = firestore.doc(global.db, "users", uid);
      const docSnap = await firestore.getDoc(docRef);
  
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());      
        const userData = docSnap.data();
        return userData;
      } else {
        console.log("No such user");
        return null;
      }
    } catch (error) {
      console.log('Error getting user data:', error);
      throw error;
    }
  }

  static async pickImage(scale = false) {
    return new Promise(async (resolve, reject) => {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: scale[0] ? true : false,
        aspect: scale[0] ? scale : null,
        quality: 1,
      });

      if (!result.canceled && result.assets && result.assets.length > 0) {
        const { uri } = result.assets[0];

        const manip = scale[0] 
          ? [{ resize: { width: scale[0], height: scale[1], }}]
          : [];

        const manipResult = await ImageManipulator.manipulateAsync(
          uri, manip,
          { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
        );
        resolve(manipResult.uri);
      } else {
        reject(new Error('Image selection cancelled'));
      }
    });
  }

  static async uploadProfilePicture(localUri) {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(localUri);
      const blob = await response.blob();
      const ref = firebase_storage.ref(global.storage, `profile_pictures/${global.user.uid}.jpg`);
      const uploadTask = firebase_storage.uploadBytesResumable(ref, blob);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // progress indicators
        },
        (error) => {
          console.log('Error uploading image:', error);
          reject(error);
        },
        async () => {
          console.log("complete");
          const downloadURL = await firebase_storage.getDownloadURL(uploadTask.snapshot.ref);
          global.user.profilePicture = downloadURL;
          console.log(global.user.profilePicture);
          //await FirebaseFunctions.updateUserInfo();
          resolve(downloadURL);
        }
      );
    });
  }

  static async uploadConsultMedia() {
    global.consult.media = [];
    return new Promise(async (resolve, reject) => {
      let uploadPromises = [];  // an array to store all the upload promises
  
      for (let [index, upload] of global.mediaUploads.entries()) {
        let uploadPromise = new Promise(async (innerResolve, innerReject) => {
          let response = await fetch(upload);
          let blob = await response.blob();
          let id = Date.now() + index;
          let ref = firebase_storage.ref(global.storage, `consult_media/${global.user.uid}/${id}.jpg`);
          let uploadTask = firebase_storage.uploadBytesResumable(ref, blob);
  
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // progress indicators
            },
            (error) => {
              console.log('Error uploading image:', error);
              innerReject(error);
            },
            async () => {
              try {
                console.log("consult media upload complete for image", index);
                let downloadURL = await firebase_storage.getDownloadURL(uploadTask.snapshot.ref);
                global.consult.media.push(downloadURL);
                innerResolve(downloadURL);  // resolve the inner promise
              } catch (err) {
                innerReject(err);
              }
            }
          );
        });
  
        uploadPromises.push(uploadPromise);  // add the promise to the array
      }
  
      // Wait for all the uploads to complete
      try {
        await Promise.all(uploadPromises);
        console.log("consult media upload complete:", global.consult.media);
        resolve(global.consult.media);  // resolve the main promise
      } catch (error) {
        console.log("Some uploads failed:", error);
        reject(error);  // reject the main promise if any upload fails
      }
    });
  }

  static async uploadConsultMedia2() {
  }

}
