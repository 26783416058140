import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableHighlight,
  Button,
  View,
} from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';
import common from '../common';

const styles = StyleSheet.create({
  uploadBoxes: {
    marginTop: 10,
    marginBottom: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadBox: {
    marginRight: 5,
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
  },
  uploadButton: {
  },
  uploadImage: {
    width: 80,
    height: 60,
    alignSelf: 'center',
  },
  uploadAdd: {
    width: 80,
    height: 60,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: "#909097",
  },
  uploadButtonText: {
    textAlign: 'center',
    fontSize: 40,
    lineHeight: 45,
    fontFamily: 'nunito',
    color: '#909097',
    fontWeight: 500,
  },
  uploadBoxShadow: {
    width: 80,
    height: 60,
    backgroundColor: '#e2e0e0',
    position: 'absolute',
    top: 4,
  }
});

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uploads: [],
    };
  }

  onSelectUpload = async (index) => {
    try {
      const localUri = await FirebaseUsers.pickImage();
      console.log('Image selected successfully:', localUri);

      //this.setState({ uploads: [...this.state.uploads,localUri] });
      let uploads = this.state.uploads;
      if (index >= 0){
        uploads[index] = localUri;
      } else {
        uploads.push(localUri);
      }
      this.setState({ uploads: uploads });
      
    } catch (error) {
      console.log('Error:', error.message);
    }
  }

  render() {
    
    return ( 
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
      <EonnTitle>Talk to an Auto Tech</EonnTitle>
        <Text style={[mstyles.text]}>Please describe your issue in detail (optional)</Text>
        <EonnField
          onChange={value => Object.assign(global.consult,{ note:value }) }
          value={global.consult.note}
          lines={10}
          >Note</EonnField>
        <Text style={[mstyles.text]}>Attach photos (optional)</Text>

        <View style={styles.uploadBoxes}>
          {this.state.uploads.map((item,index) =>

          <View style={styles.uploadBox} key={index}>
            <View style={styles.uploadBoxShadow}></View>
            <TouchableHighlight
                style={styles.uploadButton}
                underlayColor={ 'rgba(0, 0, 0, 0.2)' }
                onPress={() => this.onSelectUpload(index)}
            >
              <Image
                source={{ uri: item }}
                style={styles.uploadImage}
              />
            </TouchableHighlight>
          </View>

          )}

          <View style={styles.uploadBox}>
            <View style={styles.uploadBoxShadow}></View>
            <TouchableHighlight
                style={styles.uploadButton}
                underlayColor={ 'rgba(0, 0, 0, 0.2)' }
                onPress={() => this.onSelectUpload(-1)}
            >
              <View style={styles.uploadAdd} >
                <Text style={styles.uploadButtonText}>+</Text>
              </View>
            </TouchableHighlight>
          </View>
        </View>

        <EonnButton
          navigationProps={this.props.navigation}
          onPress={() => {
            global.mediaUploads = this.state.uploads;
            console.log('Uploads:',global.mediaUploads);
            console.log('Consult:',global.consult);
            if (global.auth.currentUser) {
              common.newConsult()
              .then(() => this.props.navigation.navigate("C_Request0"));
            } else {
              this.props.navigation.navigate('C_Profile2');
            }
          }}
          style={{marginBottom: 10}}
          >
          Next</EonnButton>
        <EonnButton
          navigationProps={this.props.navigation}
          screen={'C_Issue2'}
          white={true}
          style={{marginTop: 10}}
          param={{issuenumber : this.props.route.params.issuenumber}}
          >
          Back</EonnButton>

      </ScrollView>
    );
  }
}