import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  View,
  Modal,
} from 'react-native';


import AgoraUIKit, { PropsInterface, layout } from 'agora-react-uikit';

import ImageView from 'react-native-image-view';
import { mstyles } from '../constants/MainStyles';
import common from '../common';
import FirebaseConsult from '../firebase/firebase_consult.js'

const styles = StyleSheet.create({
  consult: {
    flex: 1,
    backgroundColor: '#fff',
    flexDirection: 'row',
  },
  issueBox: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingRight: 40,
    paddingBottom: 20,
    paddingLeft: 40,
    width: '100%',
  },
  issueBox2: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  issueImage: {
    width: 47,
    height: 47,
    marginBottom: 5,
  },
  issueText: {
    fontSize: 30,
    lineHeight: 32,
  },
  issueText2: {
    fontSize: 16,
    lineHeight: 18,
  },
  vehicleBox: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 20,
    paddingRight: 40,
    paddingBottom: 15,
    paddingLeft: 40,
    borderTopColor: '#ceced2',
    borderTopWidth: 1,
    borderBottomColor: '#ceced2',
    borderBottomWidth: 1,
    width: '100%',
  },
  vehicleText: {
    fontSize: 20,
    lineHeight: 22,
  },
  vehicleText2: {
    fontSize: 18,
    lineHeight: 20,
  },
  profileBox: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 20,
    paddingRight: 40,
    paddingBottom: 15,
    paddingLeft: 40,
    borderBottomColor: '#ceced2',
    borderBottomWidth: 1,
    width: '100%',
    marginBottom: 20,
  },
  profileText: {
    fontSize: 16,
    lineHeight: 18,
  },
  profileText2: {
    fontSize: 20,
    lineHeight: 22,
  },
  profileText3: {
    fontSize: 18,
    lineHeight: 20,
  },
  subtitle: {
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 16,
    marginTop: 0,
    marginRight: 18,
    marginBottom: 0,
    marginLeft: 12,
    maxWidth: 200,
  },
  
  uploadBoxes: {
    marginTop: 10,
    marginBottom: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadBox: {
    marginRight: 5,
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
  },
  uploadButton: {
  },
  uploadImage: {
    width: 80,
    height: 60,
    alignSelf: 'center',
  },
  uploadAdd: {
    width: 80,
    height: 60,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: "#909097",
  },
  uploadButtonText: {
    textAlign: 'center',
    fontSize: 40,
    lineHeight: 45,
    fontFamily: 'nunito',
    color: '#909097',
    fontWeight: 500,
  },
  uploadBoxShadow: {
    width: 80,
    height: 60,
    backgroundColor: '#e2e0e0',
    position: 'absolute',
    top: 4,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
  },
  modalTitle: {
    fontFamily: 'nunito',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'nunito',
    fontSize: 16,
    marginBottom: 20,
  },
  modalButtons: {
    flexDirection: 'row',
  },
  modalButton: {
    backgroundColor: '#d02e25',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginHorizontal: 10,
  },
  modalButtonText: {
    fontFamily: 'nunito',
    color: '#fff',
    fontSize: 16,
  },
});


export default class Screen extends Component {

  constructor(props) {
    console.log("consult: ",global.consult);
    console.log("channel: ",global.consult.channel);
    console.log("token: ",global.consult.token);
    super(props);

    this.state = {
      inCall: true,
      err: "",
      imageModalVisible: false,
      images: [],
      selectedImage: 0,
      showModal: false,
    };

    let images = [];
    global.consult.media.map((item,index) => {
      //get width and height from uri
      Image.getSize(item, (width, height) => {
        images.push({
          source: { uri: item },
          width: width,
          height: height,
        });
      }, (error) => {
        console.log("error getting image size: ", error);
      });
    });
    this.state.images = images;

  }

  handleEndCall = () => {
    this.setState({ showModal: true });
  }

  handleConfirmEndCall = () => {
    global.consult.status = 'ended';
    common.storeUserData()
    .then(() => FirebaseConsult.updateConsultInfo() )
    .then(() => {
      this.setState({ inCall: false, showModal: false });
      this.props.navigation.navigate('T_Consult2');
    });
  }

  handleCancelEndCall = () => {
    this.setState({ showModal: false });
  }

  setModalVisible = (visible, index) => {
    console.log("setModalVisible: ", visible, index);
    this.setState({
      imageModalVisible: visible,
      selectedImage: index || 0,
    });
  }

  render() {
    
    const agora = {
      connectionData: {
        appId: '77fcf06390ac4ca685fac7a2b37f3a81',
        channel: global.consult.channel,
        token: global.consult.token,
        layout: layout.grid,
      },
      rtcCallbacks: {
        EndCall: () => {
          this.handleEndCall();
        },
      },
      style: {
        localBtnContainer: {backgroundColor: '#17171a'}
      }
    };

    console.log(agora.connectionData.channel);
    console.log(agora.connectionData.token);
  

    return (
      <View style={styles.consult} >
        <ImageView
          images={this.state.images}
          imageIndex={this.state.selectedImage}
          isVisible={this.state.imageModalVisible}
          renderFooter={(currentImage) => (<View><Text>Customer Image</Text></View>)}
          onClose={() => this.setModalVisible(false)}
        />

        <View style={{flex:3}}>
          {(this.state.inCall) &&
            <AgoraUIKit rtcProps={agora.connectionData} callbacks={agora.rtcCallbacks} styleProps={agora.style}/>
          }
        </View>
        <View style={{flex:1}}>

          <View style={styles.issueBox}>
            <View style={styles.issueBox2}>
              <Image source={require('../assets/images/issue1.png')} style={styles.issueImage} />
              <Text style={[mstyles.mfont, styles.text, styles.issueText]}>
                {global.consult.desc}
              </Text>
            </View>
          </View>

          
          <View style={styles.vehicleBox}>
            <Image source={require('../assets/images/vehicle1.png')} style={styles.issueImage} />
            <View>
              <Text style={[mstyles.mfont, styles.text, styles.vehicleText]}>
                {global.consult.c_vehicle}
              </Text>
              <Text style={[mstyles.mfont, styles.text, styles.vehicleText2]}>
                {global.consult.c_miles} miles
              </Text>
            </View>
          </View>

          <View style={styles.profileBox}>
            <Image source={require('../assets/images/profile1.png')} style={styles.issueImage} />
            <View>
              <Text style={[mstyles.mfont, styles.text, styles.profileText]}>
                Requested by
              </Text>
              <Text style={[mstyles.mfont, styles.text, styles.profileText2]}>
                {global.consult.c_name}
              </Text>
              <Text style={[mstyles.mfont, styles.text, styles.profileText3]}>
                {common.timeDifference(global.consult.timeRequested)}
              </Text>
            </View>
          </View>

          <Text style={mstyles.text}>
            {global.consult.note}
          </Text>


          <View style={styles.uploadBoxes}>
            {global.consult.media.map((item,index) =>

            <View style={styles.uploadBox} key={index}>
              <View style={styles.uploadBoxShadow}></View>
              <TouchableHighlight
                  style={styles.uploadButton}
                  underlayColor={ 'rgba(0, 0, 0, 0.2)' }
                  onPress={() => this.setModalVisible(true, index)}
              >
                <Image
                  source={{ uri: item }}
                  style={styles.uploadImage}
                />
              </TouchableHighlight>
            </View>

            )}
          </View>
          
        </View>
        <Modal
          visible={this.state.showModal}
          transparent={true}
          animationType='fade'
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>End Consultation</Text>
              <Text style={styles.modalText}>Are you sure you want to end the consultation?</Text>
              <View style={styles.modalButtons}>
                <TouchableOpacity style={styles.modalButton} onPress={this.handleConfirmEndCall}>
                  <Text style={styles.modalButtonText}>Yes</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.modalButton} onPress={this.handleCancelEndCall}>
                  <Text style={styles.modalButtonText}>No</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    );
  }

  static navigationOptions = {
    headerLeft: null,
    headerBackVisible: false,
  };
  
}