import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnTitle from '../components/title';
import { mstyles } from '../constants/MainStyles';
import common from '../common';


export default class Screen extends Component {
  
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
      <EonnTitle>Consultation Report</EonnTitle>
        <View style={{alignItems:'flex-start', flex:1}}>
        <Text style={mstyles.subtitle}>Possible Causes</Text>
        <Text style={mstyles.text}>{global.consult.report.cause}</Text>
        <View style={mstyles.divider}></View>
        <Text style={mstyles.subtitle}>Recommendation</Text>
        <Text style={mstyles.text}>{global.consult.report.rec}</Text>
        <View style={mstyles.divider}></View>
        <Text style={mstyles.subtitle}>Repair Estimate</Text>
        <Text style={mstyles.text}>{global.consult.report.repair}</Text>
        <View style={mstyles.divider}></View>
        <Text style={mstyles.subtitle}>Who can fix</Text>
        <Text style={mstyles.text}>{global.consult.report.fix}</Text>
        <View style={mstyles.divider}></View>
        </View>
        <EonnButton navigationProps={this.props.navigation} screen={'C_Consult5'}>Next</EonnButton>
      </ScrollView>
    );
  }
}