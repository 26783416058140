import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import EonnButton from '../components/button';
import EonnTitle from '../components/title';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';

const styles = StyleSheet.create({
});

export default class Screen extends Component {
  constructor(props) {
    super(props);
    if (!global.bid) {
      global.bid = {};
    }
  }

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle style={{marginBottom:0}}>Tip Tech</EonnTitle>

        <EonnButton navigationProps={this.props.navigation} screen={'C_Consult5'}>Send Tip</EonnButton>
      </ScrollView>
    );
  }
}